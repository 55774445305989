import React from 'react';
import MapToolbar from '../features/toolbar/map_toolbar';
import { useAxios } from '../lib/AxiosContext';
import MapLeaflet from './map_leaflet';
require('leaflet/dist/leaflet.css');
require('leaflet-draw/dist/leaflet.draw.css');
require('leaflet-geosearch/assets/css/leaflet.css');
require('../css/Leaflet.Control.ZoomDisplay.css');
require('../css/map.scss');
require('../css/map-toolbar.scss');
var MapShow = function (_a) {
    var containerHeight = _a.containerHeight, containerWidth = _a.containerWidth, authState = _a.authState, authFarmId = _a.authFarmId, userAdmin = _a.userAdmin, userEmail = _a.userEmail, userFarms = _a.userFarms;
    var _b = useAxios(), setFarmId = _b.setFarmId, farmId = _b.farmId;
    return (React.createElement("div", { className: "container-fluid-map" },
        React.createElement(MapLeaflet, { containerHeight: containerHeight, containerWidth: containerWidth, userAdmin: userAdmin }),
        React.createElement(MapToolbar, { containerHeight: containerHeight, authState: authState, authFarmId: authFarmId, userAdmin: userAdmin, userEmail: userEmail, userFarms: userFarms, containerWidth: containerWidth, setFarmId: setFarmId, farmId: farmId })));
};
export default MapShow;
