import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';
import stylePropType from 'react-style-proptype';
function Loader(_a) {
    var wrapperStyle = _a.wrapperStyle, noSpinner = _a.noSpinner, fadeIn = _a.fadeIn, name = _a.name, color = _a.color;
    var defaultWrapperStyle = {
        zIndex: 1000,
        position: 'absolute',
        left: 0,
        top: 0,
        backgroundColor: 'rgba(252,252,252,0.1)',
        width: '100%',
        height: '100%',
    };
    return (React.createElement("div", { style: wrapperStyle || defaultWrapperStyle },
        React.createElement("div", { style: {
                position: 'relative',
                top: '50%',
                left: '50%',
                width: '32px',
                height: '32px',
            } }, noSpinner || (React.createElement(Spinner, { fadeIn: fadeIn || 'half', name: name || 'circle', color: color || '#bcbcbc' })))));
}
Loader.propTypes = {
    wrapperStyle: stylePropType,
    noSpinner: PropTypes.bool,
    fadeIn: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string,
};
Loader.defaultProps = {
    wrapperStyle: null,
    noSpinner: false,
    fadeIn: 'half',
    name: 'circle',
    color: '#bcbcbc',
};
export default Loader;
