import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
function IssuesModal(_a) {
    var importErrors = _a.importErrors, userAdmin = _a.userAdmin;
    return (React.createElement("div", { style: {
            padding: '12px',
            zIndex: 999,
            position: 'absolute',
            top: userAdmin ? '408px' : '368px',
            right: '24px',
            color: 'black',
            backgroundColor: 'white',
            borderRadius: '8px',
            width: '324px',
        } },
        React.createElement("p", { style: { fontWeight: '500' } }, "Please address these issues before submitting your farm for review"),
        React.createElement(ListGroup, { style: { maxHeight: '258px', overflow: 'scroll' } }, importErrors.map(function (error) {
            var _a;
            return (React.createElement(ListGroupItem, { key: error, style: { fontSize: '15px' } }, (_a = error.split(':')[1]) !== null && _a !== void 0 ? _a : error));
        }))));
}
export default IssuesModal;
