import { actionTypes } from '../constants';
import { createFailure, createSuccess, destroyFailure, destroySuccess, fetch, loadFailure, loadSuccess, reset, set, unfetch, unset, updateFailure, updateSuccess, } from '../lib/reducer_helpers';
export var initialState = {
    isFetching: false,
    collection: [],
    model: {},
};
export default function farmsReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var type = action.type, payload = action.payload;
    switch (type) {
        case actionTypes.FARM_RESET:
            return reset(initialState);
        case actionTypes.FARM_FETCH:
            return fetch(state);
        case actionTypes.FARM_UNFETCH:
            return unfetch(state);
        case actionTypes.FARM_SET:
            return set(state, payload);
        case actionTypes.FARM_UNSET:
            return unset(state);
        case actionTypes.FARM_LOAD_SUCCESS:
            return loadSuccess(initialState, payload);
        case actionTypes.FARM_LOAD_FAILURE:
            return loadFailure(initialState);
        case actionTypes.FARM_CREATE_SUCCESS:
            return createSuccess(state, payload);
        case actionTypes.FARM_CREATE_FAILURE:
            return createFailure(state);
        case actionTypes.FARM_UPDATE_SUCCESS:
            return updateSuccess(state, payload);
        case actionTypes.FARM_UPDATE_FAILURE:
            return updateFailure(state);
        case actionTypes.FARM_DESTROY_SUCCESS:
            return destroySuccess(state, payload);
        case actionTypes.FARM_DESTROY_FAILURE:
            return destroyFailure(state);
        default:
            return state;
    }
}
