import React from 'react';

import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import { intercomAppId, intercomHelpUrl } from '../env-exports';

function HelpCenter({ userEmail, userHash, userName }) {
  // intercom script from intercom installation guide https://developers.intercom.com/installing-intercom/docs/basic-javascript
  window.intercomSettings = {
    app_id: intercomAppId,
    name: userName,
    email: userEmail,
    user_id: userEmail,
    user_hash: userHash,
  };

  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/' + intercomAppId;
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === 'complete') {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();

  setTimeout(() => {
    window.location.replace(intercomHelpUrl);
  }, 3000);

  return (
    <div style={{ display: 'block', textAlign: 'center' }}>
      <h4 style={{ margin: '16px' }}>Please Wait. Redirecting...</h4>
      <Button
        style={{ color: 'white', backgroundColor: 'black', margin: '16px', height: '40px' }}
        href={intercomHelpUrl}
      >
        Take me to the Help Center
      </Button>
    </div>
  );
}

HelpCenter.propTypes = {
  userEmail: PropTypes.string.isRequired,
  userHash: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
};

export default HelpCenter;
