/* eslint jsx-a11y/label-has-associated-control: 0 */

/* eslint jsx-a11y/label-has-for: 0 */
import React, { Component } from 'react';

import {
  CIRCLE_RADIUS_PROPERTY_VALUE_CIRCLE,
  CIRCLE_RADIUS_PROPERTY_VALUE_CIRCLE_MARKER,
  GEOJSON_PROPERTY_TYPES,
  MAXIMUM_ALLOWED_NUMBER_OF_COORDINATES_FOR_COMPLEX_PADDOCKS,
  MAXIMUM_ALLOWED_NUMBER_OF_COORDINATES_FOR_POLYGONS,
  geometryPropertyTypes,
  namedPropertyTypes,
  paddockNamedPropertyTypes,
  pointAsCirclePropertyTypes,
  singularPropertyTypes,
} from '@halter-corp/geojson-feature-validator';
import { ComplexPaddockTypeEnum, PaddockTypeEnum } from '@halter-corp/topography-service-client';
import * as turf from '@turf/turf';
import axios from 'axios';
import L from 'leaflet';
import 'leaflet-draw/dist/leaflet.draw';
import { GeoSearchControl, GoogleProvider } from 'leaflet-geosearch';
import 'leaflet-polylinedecorator';
import 'leaflet.polylinemeasure';
import 'leaflet.gridlayer.googlemutant';
import _cloneDeep from 'lodash/cloneDeep';
import _debounce from 'lodash/debounce';
import _defaultTo from 'lodash/defaultTo';
import _difference from 'lodash/difference';
import _findIndex from 'lodash/findIndex';
import _first from 'lodash/first';
import _flatten from 'lodash/flatten';
import _get from 'lodash/get';
import _identity from 'lodash/identity';
import _includes from 'lodash/includes';
import _intersection from 'lodash/intersection';
import _isEqual from 'lodash/isEqual';
import _keyBy from 'lodash/keyBy';
import _maxBy from 'lodash/maxBy';
import _merge from 'lodash/merge';
import _pickBy from 'lodash/pickBy';
import _sortBy from 'lodash/sortBy';
import _uniq from 'lodash/uniq';
import _without from 'lodash/without';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';

import { mapSet } from '../actions/map_actions';
import { gateIcon } from '../assets/gate-icon';
import { markerIcon } from '../assets/marker-icon';
import { troughIcon } from '../assets/trough-icon';
import { EditFeatureModal } from '../components/edit-feature-modal';
import ToolBarHelper from '../components/tool-bar-helper';
import { GEOMETRY_TYPE_ENUM, GOOGLE_LAYER_TYPES, MAPBOX_LAYER_TYPES } from '../constants';
import { tilesHost } from '../env-exports';
import { setFeatureStyle, setTooltipName } from '../lib/feature-styles';

// GK Halter-Dev
const GOOGLE_API_TOKEN = 'AIzaSyB4Edm7OtSV6h28hHoePlXV8UW4UiEMeNo';
// GK Halter-Dev
const MAPBOX_API_TOKEN =
  'pk.eyJ1IjoiZ29yZG9ua2luZzAyIiwiYSI6ImNqb2kwZW5mczA0NHgza3Q2Ymd4Z2Q2OTAifQ.3dl9vow5mJdlU4-JLijKFA';

// Expires 2024-06-02 21:18:30
const MAXAR_API_TOKEN = 'YjhkOGUwZTYtNzkxZC00ZWIyLTljYWUtMDc0OTdjNjFmM2Ni';

const baseLayerTemplates = [
  {
    name: 'Google Maps - Hybrid',
    type: 'google',
    maptype: GOOGLE_LAYER_TYPES.HYBRID,
    url: null,
  },
  {
    name: 'Google Maps - Satellite',
    type: 'google',
    maptype: GOOGLE_LAYER_TYPES.SATELLITE,
    url: null,
  },
  {
    name: 'Google Maps - Roadmap',
    type: 'google',
    maptype: GOOGLE_LAYER_TYPES.ROADMAP,
    url: null,
  },
  {
    name: 'Google Maps - Terrain',
    type: 'google',
    maptype: GOOGLE_LAYER_TYPES.TERRAIN,
    url: null,
  },
  {
    name: 'Open Street Map - Roadmap',
    type: 'osm',
    url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
    attribution: 'OpenStreetMap',
  },
  {
    name: 'Mapbox - Street',
    type: 'mapbox',
    maptype: MAPBOX_LAYER_TYPES.STREETS,
    url: `https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=${MAPBOX_API_TOKEN}`,
    attribution:
      'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
  },
  {
    name: 'Mapbox - Satellite',
    type: 'mapbox',
    maptype: MAPBOX_LAYER_TYPES.SATELLITE,
    url: `https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=${MAPBOX_API_TOKEN}`,
    attribution:
      'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
  },
  {
    name: 'Maxar - Satellite',
    type: 'maxar',
    maptype: 'Maxar:Imagery',
    url: "https://api.maxar.com/streaming/v1/ogc/wms?cql_filter=productName='VIVID_STANDARD_30'",
  },
];

L.Icon.Default.imagePath = '//cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/';

Modal.setAppElement(document.getElementById('root'));

window.onload = function () {
  document.addEventListener('keydown', logKey);
  function logKey(e) {
    if (e.key === 'Enter' && document.getElementsByClassName('custom-black-button')[0]) {
      e.preventDefault();
      document.getElementsByClassName('custom-black-button')[0].click();
    }
  }
};

const colouredGateIcon = L.divIcon({
  html: gateIcon,
  className: '',
  iconSize: [32, 38],
  iconAnchor: [16, 38],
});

const colouredTroughIcon = L.divIcon({
  html: troughIcon,
  className: '',
  iconSize: [32, 38],
  iconAnchor: [16, 38],
});

const plainMarkerIcon = L.divIcon({
  html: markerIcon,
  className: '',
  iconSize: [32, 38],
  iconAnchor: [16, 38],
});

const modalStyles = {
  overlay: {
    zIndex: '999',
    backgroundColor: 'rgba(255, 255, 255, 0.25',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '8px',
  },
};

const MAX_VERTICE = 20;
const FEATURE_FLAG_SIMPLIFICATION_REPORTING = false;
const FEATURE_FLAG_FARM_TILES = true;
const HEADER_HEIGHT = 64;

class MapLeaflet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areas: [],
      noDeleteModalIsOpen: false,
      noEditModalIsOpen: false,
      featureModalIsOpen: false,
      featureLeafletId: '',
      featureGeometryType: '',
      featureName: '',
      featureId: '',
      featurePaddockName: '',
      featurePaddockType: '',
      featureComplexPaddockType: null,
      featureType: '',
      featureIsBlockPaddock: false,
      featurePoints: 0,
      showToolbarHelper: ['none', ''],
      enclosingPaddock: '',
      featureToEdit: '',
      createNewDestination: false,
    };

    this.drawInProgress = false;
    this.featureClickDelay = 250;
    this.featureClickTimer = 0;
    this.destinations = [];

    this.handleMapMoveend = this.handleMapMoveend.bind(this);
    this.handleBaseLayerChange = this.handleBaseLayerChange.bind(this);

    this.handleDrawStart = this.handleDrawStart.bind(this);
    this.handleDrawStop = this.handleDrawStop.bind(this);
    this.handleDrawCreated = this.handleDrawCreated.bind(this);
    this.handleDrawEdited = this.handleDrawEdited.bind(this);
    this.handleDrawDeleted = this.handleDrawDeleted.bind(this);
    this.handleCount = this.handleCount.bind(this);
    this.handleEditMove = this.handleEditMove.bind(this);
    this.setEnclosingPaddock = this.setEnclosingPaddock.bind(this);

    this.toggleNoEditModalClick = this.toggleNoEditModalClick.bind(this);
    this.toggleNoDeleteModalClick = this.toggleNoDeleteModalClick.bind(this);
    this.toggleToolbarHelper = this.toggleToolbarHelper.bind(this);

    this.handleFeatureModalOpen = this.handleFeatureModalOpen.bind(this);
    this.handleFeatureModalSave = this.handleFeatureModalSave.bind(this);
    this.handleFeatureModalCancel = this.handleFeatureModalCancel.bind(this);
    this.handleFeatureModalNameChange = this.handleFeatureModalNameChange.bind(this);
    this.handleFeatureModalPaddockNameChange = this.handleFeatureModalPaddockNameChange.bind(this);
    this.handleFeatureModalPaddockTypeChange = this.handleFeatureModalPaddockTypeChange.bind(this);
    this.handleFeatureModalComplexPaddockTypeChange = this.handleFeatureModalComplexPaddockTypeChange.bind(this);
    this.handleFeatureModalTypeChange = this.handleFeatureModalTypeChange.bind(this);
    this.handleFeatureModalIsBlockPaddockChange = this.handleFeatureModalIsBlockPaddockChange.bind(this);
    this.handleFeatureModalIsComplexPaddockChange = this.handleFeatureModalIsComplexPaddockChange.bind(this);
    this.handleFeatureModalEdit = this.handleFeatureModalEdit.bind(this);
    this.setCreateNewDestination = this.setCreateNewDestination.bind(this);
    this.handleFeatureEntryForDestinationId = this.handleFeatureEntryForDestinationId.bind(this);
    this.getRaceWaypointKeys = this.getRaceWaypointKeys.bind(this);
  }

  componentWillMount() {
    this.delayedHandleMapMoveend = _debounce(() => {
      const { mapSet: propsMapSet } = this.props;
      const mapCenter = this.map.getCenter();
      const mapZoom = this.map.getZoom();
      const mapData = {
        lat: mapCenter.lat,
        lng: mapCenter.lng,
        zoom: mapZoom,
      };
      propsMapSet(mapData);
    }, 100);
  }

  drawWaypointLinks(features) {
    if (this.waypointConnectionLayers != null) {
      this.waypointConnectionLayers.clearLayers();
    }
    const filterFeatures = this.props.map?.filter?.filterFeatures ?? [];
    if (filterFeatures.length > 0 && !filterFeatures.includes(GEOJSON_PROPERTY_TYPES.RACE_WAYPOINT)) return;

    const waypointConnections = _flatten(
      features
        .filter((feature) => feature.properties.type === GEOJSON_PROPERTY_TYPES.RACE_WAYPOINT)
        .map((feature) => feature.properties.directLinkWaypointKeys.map((key) => [feature.properties.key, key])),
    );
    const keyedWaypoints = _keyBy(
      features.filter((feature) => feature.properties.type === GEOJSON_PROPERTY_TYPES.RACE_WAYPOINT),
      (f) => f.properties.key,
    );

    this.waypointConnectionLayers = new L.GeoJSON(null, { style: { color: 'yellow' } });
    for (const waypointConnection of waypointConnections) {
      const points = waypointConnection
        .filter((key) => keyedWaypoints[key] != null)
        .map((key) => [...keyedWaypoints[key].geometry.coordinates]);
      const lineFeature = { type: 'Feature', geometry: { type: 'LineString', coordinates: points }, properties: {} };
      this.waypointConnectionLayers.addData(lineFeature);
    }
    this.map.addLayer(this.waypointConnectionLayers);
  }

  componentDidMount() {
    const { map } = this.props;
    this.map = L.map('map', {
      center: [map.lat, map.lng],
      zoom: map.zoom,
      zoomControl: false,
      worldCopyJump: true,
    })
      .on('moveend', this.handleMapMoveend)
      .on('baselayerchange', this.handleBaseLayerChange)
      .on(`${L.Draw.Event.DRAWSTART} ${L.Draw.Event.EDITSTART} ${L.Draw.Event.DELETESTART}`, this.handleDrawStart)
      .on(`${L.Draw.Event.DRAWSTOP} ${L.Draw.Event.EDITSTOP} ${L.Draw.Event.DELETESTOP}`, this.handleDrawStop)
      .on(L.Draw.Event.CREATED, this.handleDrawCreated)
      .on(L.Draw.Event.EDITED, this.handleDrawEdited)
      .on(L.Draw.Event.DELETED, this.handleDrawDeleted)
      .on(L.Draw.Event.DRAWVERTEX, this.handleCount)
      .on(L.Draw.Event.EDITVERTEX, this.handleCount);

    L.Draw.Polyline.prototype._onTouch = L.Util.falseFn;

    const Coordinates = L.Control.extend({
      onAdd: () => {
        const container = L.DomUtil.create('div', 'latlng');
        this.map.addEventListener('mousemove', (e) => {
          container.innerHTML = `
          Latitude: ${e.latlng.lat.toFixed(6)} Longitude: ${e.latlng.lng.toFixed(6)}
            `;
        });
        return container;
      },
    });
    this.map.addControl(new Coordinates({ position: 'bottomleft' }));
    L.control.zoom({ position: 'bottomright' }).addTo(this.map);
    // eslint-disable-next-line global-require
    require('../lib/Leaflet.Control.ZoomDisplay');
    L.control.zoomDisplay({ position: 'bottomright' }).addTo(this.map);
    L.control.polylineMeasure({ position: 'bottomright' }).addTo(this.map);
    L.control.scale({ position: 'bottomleft' }).addTo(this.map);
    // check if contains datum then hide
    const collectionContainsDatum = map.featureCollection.features.some(
      (feat) => feat.properties.type === 'datum',
    );
    if (!collectionContainsDatum) {
      new GeoSearchControl({
        retainZoomLevel: false,
        animateZoom: false,
        autoClose: true,
        style: 'bar',
        searchLabel: 'Enter Farm Address',
        provider: new GoogleProvider({ params: { key: GOOGLE_API_TOKEN } }),
      }).addTo(this.map);
    }

    this.baseLayers = baseLayerTemplates.map((baseLayer) => this.createBaseLayer(baseLayer));
    const baseLayersMenu = this.baseLayers.reduce(
      (result, layer) => _merge({}, result, { [layer.name]: layer.element }),
      {},
    );
    this.layersControl = L.control.layers(baseLayersMenu, {}, { position: 'bottomleft' });
    this.layersControl.addTo(this.map);
    baseLayersMenu[this.baseLayers[map.baseLayerIndex].name].addTo(this.map);

    this.editableLayers = new L.GeoJSON(null, {
      style: (feature) => setFeatureStyle(feature),
      onEachFeature: (feature, layer) => {
        layer.on('add', (e) => {
          const addLayer = e.target;
          addLayer.bindTooltip(setTooltipName(addLayer), { direction: 'center', sticky: 'true', offset: [0, -40] });
        });
        layer.on('click', (e) => {
          if (!this.drawInProgress) {
            clearTimeout(this.featureClickTimer);
            this.featureClickTimer = setTimeout(() => {
              this.handleFeatureModalOpen(e.target, e.latlng);
            }, this.featureClickDelay);
          }
        });
        layer.on('dblclick', (e) => {
          if (!this.drawInProgress) {
            clearTimeout(this.featureClickTimer);
            this.map.fire('dblclick', e);
          }
        });
        layer.on('move', (e) => {
          this.handleEditMove(e);
        });
      },
      pointToLayer: (feature, latlng) => {
        if (feature.properties.circleType) {
          if (feature.properties.type === GEOJSON_PROPERTY_TYPES.TROUGH) {
            return L.marker(latlng, { icon: colouredTroughIcon });
          }
          if (feature.properties.circleType === CIRCLE_RADIUS_PROPERTY_VALUE_CIRCLE) {
            return L.circle(latlng, feature.properties.circleRadius, { shapeOptions: setFeatureStyle(feature) });
          }
          if (feature.properties.circleType === CIRCLE_RADIUS_PROPERTY_VALUE_CIRCLE_MARKER) {
            return L.circleMarker(latlng, feature.properties.circleRadius, { shapeOptions: setFeatureStyle(feature) });
          }
        }
        if (feature.properties.type === GEOJSON_PROPERTY_TYPES.EXIT_POINT) {
          return L.marker(latlng, { icon: colouredGateIcon });
        }
        if (feature.properties.type === GEOJSON_PROPERTY_TYPES.RACE_WAYPOINT) {
          const className = feature.properties.isShiftDestination
            ? 'leaflet-number-destination-icon'
            : feature.properties.entryForDestinationId != null
              ? 'leaflet-number-entry-icon'
              : 'leaflet-number-icon';
          return L.marker(latlng, {
            icon: L.divIcon({
              className: className,
              iconSize: [30, 30],
              html: feature.properties.key,
            }),
          });
        }
        if (feature.properties.type === GEOJSON_PROPERTY_TYPES.DATUM) {
          return L.marker(latlng, { icon: plainMarkerIcon });
        }
        return L.marker(latlng);
      },
    });
    const forwardLines = map.featureCollection.features
      .filter((feature) => feature.geometry.type === 'LineString')
      .map((feature) => {
        const { coordinates } = feature.geometry;
        coordinates.forEach((coordinate) => coordinate.reverse());
        return L.polyline(coordinates);
      });
    //  Arrow at the end of the path
    const pathEndArrow = L.polylineDecorator(forwardLines, {
      patterns: [
        {
          offset: '100%',
          repeat: 0,
          symbol: L.Symbol.arrowHead({
            pixelSize: 10,
            polygon: true,
            pathOptions: { stroke: true, color: 'green', fillOpacity: 1 },
          }),
        },
      ],
    }).addTo(this.map);

    //  Arrow at the start of the path
    const pathStartArrow = L.polylineDecorator(forwardLines, {
      patterns: [
        {
          offset: '0%',
          repeat: 0,
          symbol: L.Symbol.arrowHead({
            pixelSize: 10,
            polygon: true,
            pathOptions: { stroke: true, color: 'red', fillOpacity: 1 },
          }),
        },
      ],
    }).addTo(this.map);

    this.map.addLayer(this.editableLayers, pathEndArrow, pathStartArrow);

    this.filteredLayers = new L.GeoJSON(null);
    if (FEATURE_FLAG_SIMPLIFICATION_REPORTING) {
      this.simplifiedLayers = new L.GeoJSON(null);
      this.map.addLayer(this.simplifiedLayers);
    }

    const drawOptions = {
      position: 'topright',
      draw: {
        circlemarker: false,
        polyline: {
          shapeOptions: {
            color: '#ffff00',
            weight: 3,
          },
        },
        polygon: {
          allowIntersection: false,
          showArea: true,
          drawError: {
            color: '#e1e100',
            message: "<strong>Oh snap!<strong> you can't draw that!",
          },
        },
        rectangle: false,
        marker: {
          icon: colouredGateIcon,
        },
      },
      edit: {
        featureGroup: this.editableLayers,
      },
    };
    L.drawLocal.draw.toolbar.buttons.polygon = null;
    L.drawLocal.draw.toolbar.buttons.polyline = null;
    L.drawLocal.draw.toolbar.buttons.circle = null;
    L.drawLocal.draw.toolbar.buttons.marker = null;
    L.drawLocal.draw.handlers.polygon.tooltip.start = 'Click to start drawing';
    L.EditToolbar.Delete.include({
      removeAllLayers: false,
    });
    this.drawControl = new L.Control.Draw(drawOptions);
    this.redrawMap();

    const toolBarElement = document.querySelector('.leaflet-right');
    toolBarElement.addEventListener('mouseover', (event) => {
      this.toggleToolbarHelper('block', event.target.className);
    });
    toolBarElement.addEventListener('mouseout', (event) => {
      this.toggleToolbarHelper('none', event.target.className);
    });
    this.redrawAerialLayer();
  }

  componentDidUpdate(prevProps) {
    const { map } = this.props;
    const { featureCollectionSourceId } = map;

    if (prevProps.map.baseLayerIndex !== map.baseLayerIndex) {
      if (this.map.hasLayer(this.baseLayers[prevProps.map.baseLayerIndex].element)) {
        this.map.removeLayer(this.baseLayers[prevProps.map.baseLayerIndex].element);
        this.map.addLayer(this.baseLayers[map.baseLayerIndex].element);
      }
    }
    if (
      (prevProps.map.zoom !== map.zoom && map.zoom !== this.map.getZoom()) ||
      (prevProps.map.lat !== map.lat && map.lat !== this.map.getCenter().lat) ||
      (prevProps.map.lng !== map.lng && map.lng !== this.map.getCenter().lng)
    ) {
      this.map.setView([map.lat, map.lng], map.zoom);
    }
    if (
      prevProps.map.featureCollectionSourceId !== featureCollectionSourceId ||
      (prevProps.map.rebased && !map.rebased) ||
      !_isEqual(prevProps.map.filter, map.filter)
    ) {
      this.redrawMap();
      this.redrawAerialLayer(prevProps.map.featureCollectionSourceId);
    }
  }

  componentWillUnmount() {
    this.delayedHandleMapMoveend.cancel();
    this.map.remove();
    this.map = null;
  }

  setAreas() {
    const results = [];
    const areas = this.editableLayers
      .getLayers()
      .filter((layer) => _includes([GEOJSON_PROPERTY_TYPES.PADDOCK], layer.feature.properties.type))
      .map((layer) => {
        const {
          feature,
          feature: {
            properties: { name },
          },
        } = layer;
        let length;
        const areaSize = turf.round(turf.area(feature));
        const segmentsCount = _first(turf.getCoords(feature)).length - 1;
        let simpleSegmentsCount;
        let simpleAreaSize;
        if (FEATURE_FLAG_SIMPLIFICATION_REPORTING && segmentsCount > MAX_VERTICE) {
          length = turf.length(turf.polygonToLine(feature));
          let divisor = 100000;
          let simplifiedFeature;
          let tolerance;
          while (!simpleSegmentsCount || simpleSegmentsCount > MAX_VERTICE) {
            tolerance = length / segmentsCount / divisor;
            simplifiedFeature = turf.simplify(feature, { tolerance });
            simpleSegmentsCount = _first(turf.getCoords(simplifiedFeature)).length - 1;
            divisor *= 0.99;
          }
          // Add to layer
          if (simplifiedFeature) {
            this.simplifiedLayers.addData(simplifiedFeature);
            simpleAreaSize = turf.round(turf.area(simplifiedFeature));
          }
        }
        results.push([segmentsCount, simpleSegmentsCount || segmentsCount, areaSize, simpleAreaSize || areaSize]);
        return {
          name,
          areaSize,
          segmentsCount,
          simpleSegmentsCount,
        };
      });

    this.setState({
      areas: _sortBy(areas, ['name']),
    });
  }

  getPaddockNames = () =>
    this.editableLayers
      .getLayers()
      .filter((l) => l.feature.properties.type === GEOJSON_PROPERTY_TYPES.PADDOCK)
      .map((l) => l.feature.properties.name);

  getRaceWaypointKeys = () =>
    this.editableLayers
      .getLayers()
      .filter((l) => l.feature.properties.type === 'race-waypoint')
      .map((l) => l.feature.properties.key)
      .sort((a, b) => a - b);

  getFeatureTypeOptionsData(featureTypes) {
    const {
      map: {
        filter: { filterFeatures, filterPaddockName },
      },
    } = this.props;

    let newFeatureTypes = _cloneDeep(featureTypes);

    if (filterFeatures.length > 0) {
      newFeatureTypes = _intersection(newFeatureTypes, filterFeatures);
    }

    if (filterPaddockName) {
      newFeatureTypes = _without(newFeatureTypes, GEOJSON_PROPERTY_TYPES.PADDOCK);
    }

    return newFeatureTypes.map((featureType) => {
      const option = {
        name: featureType,
        disabled: false,
      };
      if (_includes(singularPropertyTypes, featureType)) {
        const { featureLeafletId } = this.state;
        const currentLayer = this.editableLayers.getLayers().find((l) => l.feature.properties.type === featureType);
        // eslint-disable-next-line no-underscore-dangle
        if (currentLayer && currentLayer._leaflet_id !== featureLeafletId) {
          option.disabled = true;
        }
      }
      if (_includes(paddockNamedPropertyTypes, featureType)) {
        const currentLayer = this.editableLayers
          .getLayers()
          .find((l) => l.feature.properties.type === GEOJSON_PROPERTY_TYPES.PADDOCK);
        // eslint-disable-next-line no-underscore-dangle
        if (!currentLayer) {
          option.disabled = true;
        }
      }
      return option;
    });
  }

  getPointFeatureTypeOptions() {
    const { featureLeafletId } = this.state;
    const layer = this.editableLayers.getLayer(featureLeafletId);
    if (layer instanceof L.Circle) {
      return this.getFeatureTypeOptionsData(pointAsCirclePropertyTypes);
    } else {
      return this.getFeatureTypeOptionsData(_difference(geometryPropertyTypes.Point, pointAsCirclePropertyTypes));
    }
  }

  getFeatureTypeOptions() {
    const { featureGeometryType } = this.state;
    switch (featureGeometryType) {
      case GEOMETRY_TYPE_ENUM.LINE_STRING:
      case GEOMETRY_TYPE_ENUM.POLYGON:
        return this.getFeatureTypeOptionsData(
          // farm boundaries will be automatically generated for now; non-harvestable-area type deprecated
          _difference(geometryPropertyTypes[featureGeometryType], [
            GEOJSON_PROPERTY_TYPES.FARM_BOUNDARY,
            GEOJSON_PROPERTY_TYPES.NON_HARVESTABLE_AREA,
          ]),
        );
      case GEOMETRY_TYPE_ENUM.POINT:
        return this.getPointFeatureTypeOptions();
      default:
        return [];
    }
  }

  isFeaturePropsInvalid = (featureProps) => {
    const { type, name, paddockName } = featureProps;
    let sameName = false;
    if (type && type === GEOJSON_PROPERTY_TYPES.PADDOCK && name) {
      const { featureLeafletId } = this.state;
      const sameLayer = this.editableLayers
        .getLayers()

        .filter(
          (l) =>
            l.feature.properties.type === GEOJSON_PROPERTY_TYPES.PADDOCK &&
            // eslint-disable-next-line no-underscore-dangle
            l._leaflet_id !== featureLeafletId,
        )
        .find((l) => l.feature.properties.name === name);
      if (sameLayer) {
        sameName = true;
      }
    }
    if (type && type === GEOJSON_PROPERTY_TYPES.EXIT_POINT && name) {
      const { featureLeafletId } = this.state;
      const sameLayer = this.editableLayers
        .getLayers()
        .filter(
          (l) =>
            l.feature.properties.type === GEOJSON_PROPERTY_TYPES.EXIT_POINT &&
            l.feature.properties.paddockName === paddockName &&
            // eslint-disable-next-line no-underscore-dangle
            l._leaflet_id !== featureLeafletId,
        )
        .find((l) => l.feature.properties.name === name || l.feature.properties.name === `Gate ${name}`);
      if (sameLayer) {
        sameName = true;
      }
    }
    return (
      sameName ||
      !type ||
      type === '' ||
      (type === GEOJSON_PROPERTY_TYPES.PADDOCK && name === '') ||
      (type === GEOJSON_PROPERTY_TYPES.EXIT_POINT && paddockName === '')
    );
  };

  createBaseLayer = (baseLayer) => {
    const { type, maptype, url, attribution } = baseLayer;
    let element;
    if (type === 'google') {
      element = L.gridLayer.googleMutant({ type: maptype });
    } else if (type === 'osm') {
      element = L.tileLayer(url, { attribution });
    } else if (type === 'mapbox') {
      element = L.tileLayer(url, { attribution, id: maptype, accessToken: MAPBOX_API_TOKEN });
    } else if (type === 'maxar') {
      const mapOptions = {
        maxar_api_token: MAXAR_API_TOKEN,
        layers: maptype,
        maxZoom: 21,
      };
      element = L.tileLayer.wms(url, mapOptions);
    }
    return Object.assign({}, baseLayer, { element });
  };

  orderFeatures() {
    const orderedTypes = [GEOJSON_PROPERTY_TYPES.PADDOCK];
    orderedTypes.forEach((orderedType) => {
      this.editableLayers
        .getLayers()
        .filter((layer) => layer.feature.properties.type === orderedType)
        .forEach((layer) => {
          layer.bringToFront();
        });
    });
    this.editableLayers
      .getLayers()
      .filter((layer) => !_includes(orderedTypes, layer.feature.properties.type))
      .forEach((layer) => {
        if (layer instanceof L.Marker) {
          layer.setZIndexOffset(1000);
        } else {
          layer.bringToFront();
        }
      });
  }

  redrawMap() {
    const {
      map,
      map: {
        filter: { filterFeatures, filterPaddockName, filterFeatureById },
      },
      mapSet: propsMapSet,
    } = this.props;

    this.drawWaypointLinks(map.featureCollection.features);
    this.destinations = map.featureCollection.features.filter(
      (f) => f.properties.type === 'race-waypoint' && f.properties.isShiftDestination === true,
    );

    if (this.drawInProgress) {
      // eslint-disable-next-line no-underscore-dangle
      this.drawControl._toolbars.draw.disable();
      // eslint-disable-next-line no-underscore-dangle
      this.drawControl._toolbars.edit.disable();
    }
    this.editableLayers.clearLayers();
    this.filteredLayers.clearLayers();
    if (FEATURE_FLAG_SIMPLIFICATION_REPORTING) {
      this.simplifiedLayers.clearLayers();
    }
    if (map.featureCollection.features.length > 0) {
      map.featureCollection.features.forEach((feature) => {
        if (filterFeatureById != null && filterFeatureById.length > 0) {
          const idsToShow = filterFeatureById.split(',').map((id) => id.trim());
          if (idsToShow.includes(feature.properties.id)) {
            this.editableLayers.addData(feature);
          } else {
            this.filteredLayers.addData(feature);
          }
          return;
        }
        if (filterFeatures.length === 0 || _includes(filterFeatures, feature.properties.type)) {
          if (filterPaddockName === '') {
            this.editableLayers.addData(feature);
          } else if (
            _includes([GEOJSON_PROPERTY_TYPES.PADDOCK, GEOJSON_PROPERTY_TYPES.EXIT_POINT], feature.properties.type)
          ) {
            if (_includes([feature.properties.name, feature.properties.paddockName], filterPaddockName)) {
              this.editableLayers.addData(feature);
            } else {
              this.filteredLayers.addData(feature);
            }
          } else {
            this.editableLayers.addData(feature);
          }
        } else {
          this.filteredLayers.addData(feature);
        }
      });
      this.orderFeatures();
    }
    if (map.featureCollectionSourceId) {
      const drawControlElement = document.querySelectorAll('.leaflet-draw.leaflet-control');
      if (drawControlElement.length === 0) {
        this.drawControl.addTo(this.map);
      }
    } else {
      this.drawControl.remove();
    }
    this.setAreas();
    propsMapSet({
      rebased: true,
    });
  }

  removeAerialLayer() {
    if (this.aerialLayer) {
      this.aerialLayer.remove();
      this.layersControl.removeLayer(this.aerialLayer);
    }
    this.aerialLayer = null;
  }

  redrawAerialLayer(prevFeatureCollectionSourceId = '') {
    if (FEATURE_FLAG_FARM_TILES) {
      const { map } = this.props;
      if (map.featureCollectionSourceId) {
        if (map.featureCollectionSourceId !== prevFeatureCollectionSourceId) {
          this.removeAerialLayer();

          this.aerialLayer = L.tileLayer(`${tilesHost}/${map.featureCollectionSourceId}/{z}/{x}/{y}.png`, {
            attribution: `${new Date().getFullYear()} Halter Ltd. All rights reserved.`,
            minZoom: 10,
            maxZoom: 23,
          });
          this.layersControl.addOverlay(this.aerialLayer, 'Aerial');
          this.aerialLayer.addTo(this.map);
        }
      } else {
        this.removeAerialLayer();
      }
    }
  }

  createFeatureCollection() {
    const { mapSet: propsMapSet } = this.props;
    const newFeatureCollection = {
      type: 'FeatureCollection',
      features: [],
    };
    this.editableLayers.eachLayer((layer) => {
      newFeatureCollection.features.push(layer.toGeoJSON());
    });
    this.filteredLayers.eachLayer((layer) => {
      newFeatureCollection.features.push(layer.toGeoJSON());
    });
    propsMapSet({ featureCollection: newFeatureCollection, persisted: false });
    this.setAreas();
  }

  lastEditableLayersLayer() {
    // eslint-disable-next-line no-underscore-dangle
    const maxKey = _maxBy(Object.keys(this.editableLayers._layers), (key) => parseInt(key, 10));
    // eslint-disable-next-line no-underscore-dangle
    return this.editableLayers._layers[maxKey];
  }

  handleDrawStart() {
    this.drawInProgress = true;
  }

  handleDrawStop() {
    this.drawInProgress = false;
    this.setState({
      featurePoints: 0,
      featureComplexPaddockType: null,
    });
    this.overrideFeatures = null;
  }

  handleCount(e) {
    if (!e) return;
    if (e.type === 'draw:drawvertex') {
      const featurePoints = Object.keys(e.layers._layers).length;
      this.setState({
        featurePoints,
        featureComplexPaddockType: e.poly?.feature?.properties?.complexPaddockType,
      });
    } else if (e.type === 'draw:editvertex') {
      const featurePoints = e.poly.editing.latlngs[0][0]?.length ?? e.poly.editing.latlngs[0].length;
      this.setState({
        featurePoints,
        featureComplexPaddockType: e.poly?.feature?.properties?.complexPaddockType,
      });
    }
  }

  handleEditMove(e) {
    const featureMoved = e.target.feature;
    if (featureMoved.properties.type === GEOJSON_PROPERTY_TYPES.RACE_WAYPOINT) {
      const features = (this.overrideFeatures ?? this.editableLayers.getLayers().map((layer) => layer.feature)).filter(
        (f) => f.properties.key !== featureMoved.properties.key,
      );
      const overrideFeatures = [
        ...features,
        { ...featureMoved, geometry: { ...featureMoved.geometry, coordinates: [e.latlng.lng, e.latlng.lat] } },
      ];
      this.overrideFeatures = overrideFeatures;
      this.drawWaypointLinks(overrideFeatures);
    }
  }

  setEnclosingPaddock(paddock) {
    this.setState({
      enclosingPaddock: paddock[0].properties.name,
    });
  }

  handleDrawCreated(e) {
    const drawLayer = e.layer;
    let feature;
    if (drawLayer.type) {
      feature = drawLayer;
    } else {
      feature = drawLayer.toGeoJSON();
    }
    feature.properties = feature.properties || {};
    if (drawLayer instanceof L.Circle) {
      feature.properties.circleType = CIRCLE_RADIUS_PROPERTY_VALUE_CIRCLE;
      feature.properties.circleRadius = drawLayer.getRadius();
    } else if (drawLayer instanceof L.CircleMarker) {
      feature.properties.circleType = CIRCLE_RADIUS_PROPERTY_VALUE_CIRCLE_MARKER;
      feature.properties.circleRadius = drawLayer.getRadius();
    }

    if (feature.geometry.type === GEOMETRY_TYPE_ENUM.POINT) {
      // check if the feature is inside another feature
      const featureInFeature = this.props.map.featureCollection.features.filter((feat) => {
        if (feat.properties.type !== GEOJSON_PROPERTY_TYPES.PADDOCK) {
          return false;
        }
        return turf.booleanContains(feat, feature);
      });
      if (featureInFeature.length !== 0) {
        this.setEnclosingPaddock(featureInFeature);
        // if it is not inside another feature and it's a circle (trough) then prompt to create a paddock first
        // and prevent them from creating a feature
      }
    }
    this.editableLayers.addData(feature);
    this.createFeatureCollection();
    this.handleFeatureModalOpen(this.lastEditableLayersLayer());
  }

  toggleToolbarHelper(display, target) {
    this.setState({
      showToolbarHelper: [display, target, this.props.userAdmin],
    });
  }

  handleDrawEdited() {
    this.createFeatureCollection();
  }

  handleDrawDeleted(deletedLayers) {
    const deletedPaddocks = deletedLayers.layers
      .getLayers()
      .filter((layer) => layer.feature.properties.type === GEOJSON_PROPERTY_TYPES.PADDOCK);
    let restored = false;
    if (deletedPaddocks.length > 0) {
      const layers = [...this.editableLayers.getLayers(), ...this.filteredLayers.getLayers()];
      deletedPaddocks.forEach((deletedPaddock) => {
        const dependentLayer = layers.find(
          (layer) => layer.feature.properties.paddockName === deletedPaddock.feature.properties.name,
        );
        if (dependentLayer) {
          restored = true;
          this.editableLayers.addData(deletedPaddock.feature);
        }
      });
    }
    const deletedWaypoints = deletedLayers.layers
      .getLayers()
      .filter((layer) => layer.feature.properties.type === GEOJSON_PROPERTY_TYPES.RACE_WAYPOINT);
    deletedWaypoints.forEach((waypoint) => {
      if (waypoint.feature.properties.directLinkWaypointKeys.length > 0) {
        restored = true;
        this.editableLayers.addData(waypoint.feature);
      }
    });

    if (restored) {
      this.setState({ noDeleteModalIsOpen: true });
      this.orderFeatures();
    }
    this.createFeatureCollection();
  }

  handleBaseLayerChange(e) {
    const { mapSet: propsMapSet } = this.props;
    const baseLayerIndex = _findIndex(this.baseLayers, { name: e.name });
    if (baseLayerIndex > -1) {
      propsMapSet({ baseLayerIndex });
    }
  }

  handleMapMoveend(e) {
    this.delayedHandleMapMoveend(e);
  }

  renameDependents(currentName, featureName) {
    if (currentName !== featureName) {
      this.editableLayers
        .getLayers()
        .filter((layer) => layer.feature.properties.paddockName === currentName)
        .forEach((layer) => {
          // eslint-disable-next-line no-param-reassign
          layer.feature.properties = _pickBy(
            {
              ...layer.feature.properties,
              paddockName: featureName,
            },
            _identity,
          );
          return layer;
        });

      this.filteredLayers
        .getLayers()
        .filter((layer) => layer.feature.properties.paddockName === currentName)
        .forEach((layer) => {
          // eslint-disable-next-line no-param-reassign
          layer.feature.properties = _pickBy(
            {
              ...layer.feature.properties,
              paddockName: featureName,
            },
            _identity,
          );
          return layer;
        });
    }
  }

  reconcileWaypointLinks(key, links) {
    this.editableLayers
      .getLayers()
      .filter(
        (layer) =>
          layer.feature.properties.type === GEOJSON_PROPERTY_TYPES.RACE_WAYPOINT &&
          layer.feature.properties.key !== key,
      )
      .forEach((layer) => {
        // eslint-disable-next-line no-param-reassign
        if (links.includes(layer.feature.properties.key)) {
          layer.feature.properties.directLinkWaypointKeys = _uniq([
            ...layer.feature.properties.directLinkWaypointKeys,
            key,
          ]);
        } else {
          layer.feature.properties.directLinkWaypointKeys = layer.feature.properties.directLinkWaypointKeys.filter(
            (k) => k !== key,
          );
        }
      });

    this.filteredLayers
      .getLayers()
      .filter(
        (layer) =>
          layer.feature.properties.type === GEOJSON_PROPERTY_TYPES.RACE_WAYPOINT &&
          layer.feature.properties.key !== key,
      )
      .forEach((layer) => {
        // eslint-disable-next-line no-param-reassign
        if (links.includes(layer.feature.properties.key)) {
          layer.feature.properties.directLinkWaypointKeys = _uniq([
            ...layer.feature.properties.directLinkWaypointKeys,
            key,
          ]);
        } else {
          layer.feature.properties.directLinkWaypointKeys = layer.feature.properties.directLinkWaypointKeys.filter(
            (k) => k !== key,
          );
        }
      });

    this.drawWaypointLinks(this.editableLayers.getLayers().map((layer) => layer.feature));
  }

  toggleNoDeleteModalClick() {
    const { noDeleteModalIsOpen } = this.state;
    this.setState({ noDeleteModalIsOpen: !noDeleteModalIsOpen });
  }

  toggleNoEditModalClick() {
    const { noEditModalIsOpen } = this.state;
    this.setState({ noEditModalIsOpen: !noEditModalIsOpen });
  }

  resetFeatureState() {
    this.setState({
      featureModalIsOpen: false,
      featureLeafletId: '',
      featureGeometryType: '',
      featureName: '',
      featurePaddockName: '',
      featureId: '',
      featurePaddockType: '',
      featureComplexPaddockType: null,
      featureType: '',
      featureIsBlockPaddock: false,
      featureKey: '',
    });
  }

  handleFeatureModalOpen(layer) {
    const {
      map: {
        featureCollection: { features },
        filter: { filterPaddockName },
      },
    } = this.props;

    const filterType = _get(layer, 'feature.properties.type', '');

    if (filterPaddockName && filterType === GEOJSON_PROPERTY_TYPES.PADDOCK) {
      this.setState({
        noEditModalIsOpen: true,
      });
    } else {
      const raceWaypointKeys = features
        .filter((feature) => feature.properties.type === GEOJSON_PROPERTY_TYPES.RACE_WAYPOINT)
        .map((feature) => feature.properties.key);
      let newKey = 1;
      while (raceWaypointKeys.includes(newKey)) {
        newKey++;
      }

      this.setState({
        featureModalIsOpen: true,
        featureLeafletId: _get(layer, '_leaflet_id', ''),
        featureGeometryType: _get(layer, 'feature.geometry.type', ''),
        featureName: _get(layer, 'feature.properties.name', ''),
        featureId: _get(layer, 'feature.properties.id', ''),
        featurePaddockName: _get(layer, 'feature.properties.paddockName', ''),
        featurePaddockType: _get(layer, 'feature.properties.paddockType', ''),
        featureComplexPaddockType: _get(layer, 'feature.properties.complexPaddockType', null),
        featureType: _get(layer, 'feature.properties.type', ''),
        featureIsBlockPaddock: _get(layer, 'feature.properties.isBlockPaddock', false),
        featureKey: _get(layer, 'feature.properties.key', newKey),
        featureDirectLinkWaypointKeys: _get(layer, 'feature.properties.directLinkWaypointKeys', []),
        featureIsShiftDestination: _get(layer, 'feature.properties.isShiftDestination', false),
        featureEntryForDestinationId: _get(layer, 'feature.properties.entryForDestinationId', null),
        featureToEdit: layer,
      });
    }
  }

  handleFeatureModalSave() {
    const {
      featureLeafletId,
      featureName,
      featurePaddockName,
      featurePaddockType,
      featureComplexPaddockType,
      featureType,
      featureIsBlockPaddock,
      featureKey,
      featureDirectLinkWaypointKeys,
      featureIsShiftDestination,
      featureEntryForDestinationId,
    } = this.state;
    const layer = this.editableLayers.getLayer(featureLeafletId);
    const currentName = layer.feature.properties.name;
    const paddockType = featurePaddockType || PaddockTypeEnum.NORMAL;
    const gateName =
      featureType === GEOJSON_PROPERTY_TYPES.EXIT_POINT && !featureName.includes('Gate')
        ? `Gate ${featureName}`
        : featureName;
    const properties = _pickBy(
      {
        ...layer.feature.properties,
        paddockType,
        name: featureType === GEOJSON_PROPERTY_TYPES.EXIT_POINT ? gateName : featureName,
        paddockName: featurePaddockName,
        type: featureType,
        isBlockPaddock: featureIsBlockPaddock,
        key: parseInt(featureKey),
        directLinkWaypointKeys: featureDirectLinkWaypointKeys.map((k) => parseInt(k)),
      },
      _identity,
    );
    layer.feature.properties = {
      ...properties,
      complexPaddockType: featureComplexPaddockType,
      isShiftDestination: featureIsShiftDestination,
      entryForDestinationId: featureEntryForDestinationId,
    };
    if (this.isFeaturePropsInvalid(layer.feature.properties)) {
      this.editableLayers.removeLayer(featureLeafletId);
    } else {
      if (!(layer instanceof L.Marker)) {
        layer.setStyle(setFeatureStyle(layer.feature));
      }
      layer.unbindTooltip();
      layer.bindTooltip(setTooltipName(layer), { direction: 'center' });
    }
    if (featureType === GEOJSON_PROPERTY_TYPES.PADDOCK && currentName) {
      this.renameDependents(currentName, featureName);
    }
    if (featureType === GEOJSON_PROPERTY_TYPES.RACE_WAYPOINT) {
      this.reconcileWaypointLinks(
        parseInt(featureKey),
        featureDirectLinkWaypointKeys.map((k) => parseInt(k)),
      );
    }
    this.createFeatureCollection();
    this.resetFeatureState();
  }

  handleFeatureModalCancel() {
    const { featureLeafletId } = this.state;
    const layer = this.editableLayers.getLayer(featureLeafletId);
    if (layer != null) {
      if (this.isFeaturePropsInvalid(layer.feature.properties)) {
        layer.unbindTooltip();
        this.editableLayers.removeLayer(featureLeafletId);
        this.createFeatureCollection();
      }
    }
    this.resetFeatureState();
  }

  handleFeatureModalEdit() {
    const { featureToEdit } = this.state;
    const { map, mapSet: propsMapSet } = this.props;
    propsMapSet({
      filter: {
        ...map.filter,
        filterPaddockName: featureToEdit.feature.properties.paddockName ?? featureToEdit.feature.properties.name,
      },
    });
    this.setState({
      featureModalIsOpen: false,
    });
  }

  handleFeatureModalTypeChange(e) {
    this.setState({
      featureType: e.currentTarget.value,
      ...(!_includes(namedPropertyTypes, e.currentTarget.value) && { featureName: '' }),
      ...(!_includes(paddockNamedPropertyTypes, e.currentTarget.value) && { featurePaddockName: '' }),
    });
  }

  handleFeatureModalNameChange(e) {
    const newFeatureName = e.currentTarget.value;
    if (newFeatureName === '') {
      this.setCreateNewDestination(true);
    }
    this.setState({ featureName: newFeatureName });
  }

  handleFeatureEntryForDestinationId(e) {
    let destinationId = e.currentTarget.value;
    if (destinationId === '') destinationId = null;
    this.setState({ featureEntryForDestinationId: destinationId });
  }

  setCreateNewDestination(value) {
    this.setState({
      createNewDestination: value,
    });
    if (!value) {
      this.setState({
        featureName: '',
      });
    }
  }

  handleFeatureModalPaddockNameChange(e) {
    this.setState({
      featurePaddockName: e.currentTarget.value,
    });
  }

  handleFeatureModalPaddockTypeChange(e) {
    const featurePaddockType = _defaultTo(e.currentTarget.value, PaddockTypeEnum.NORMAL);
    this.setState({
      featurePaddockType,
    });
  }

  handleFeatureModalComplexPaddockTypeChange(e) {
    const complexPaddockType = _defaultTo(e.currentTarget.value, ComplexPaddockTypeEnum.LOW_COMPLEXITY_PADDOCK);
    this.setState({
      featureComplexPaddockType: complexPaddockType,
    });
  }

  handleFeatureModalIsBlockPaddockChange(e) {
    const featureIsBlockPaddock = _defaultTo(e.currentTarget.checked, false);
    this.setState({
      featureIsBlockPaddock,
    });
  }

  handleFeatureModalIsComplexPaddockChange(e) {
    const isComplexPaddock = _defaultTo(e.currentTarget.checked, false);
    let complexPaddockType;
    if (isComplexPaddock) {
      complexPaddockType = ComplexPaddockTypeEnum.LOW_COMPLEXITY_PADDOCK;
    } else {
      complexPaddockType = null;
    }
    this.setState({
      featureComplexPaddockType: complexPaddockType,
    });
  }

  renderAreas() {
    const { areas } = this.state;
    areas.sort((a, b) => a.name - b.name);
    return (
      <table style={{ margin: '16px', width: '200px' }}>
        <thead>
          <tr>
            <td>Paddock</td>
            <td>
              Area m<sup>2</sup>
            </td>
            <td>Points</td>
          </tr>
        </thead>
        <tbody>
          {areas.map((area) => {
            const { name, areaSize, segmentsCount, simpleSegmentsCount } = area;
            return (
              <tr key={name || 'editing'}>
                <td>{name}</td>
                <td style={{ textAlign: 'center' }}>{areaSize.toLocaleString()}</td>
                <td style={{ textAlign: 'right' }}>{segmentsCount}</td>
                {simpleSegmentsCount && (
                  <React.Fragment>
                    <td>&nbsp;|&nbsp;</td>
                    <td>{segmentsCount}</td>
                    <td>&nbsp;|&nbsp;</td>
                    <td>{simpleSegmentsCount}</td>
                  </React.Fragment>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  renderPoints() {
    const { featurePoints, featureComplexPaddockType } = this.state;
    if (featurePoints == null) return null;

    const maxNumberOfPoints =
      (featureComplexPaddockType
        ? MAXIMUM_ALLOWED_NUMBER_OF_COORDINATES_FOR_COMPLEX_PADDOCKS
        : MAXIMUM_ALLOWED_NUMBER_OF_COORDINATES_FOR_POLYGONS) - 1;
    return (
      <>
        Points: {featurePoints}/{maxNumberOfPoints}
      </>
    );
  }

  render() {
    const {
      noDeleteModalIsOpen,
      noEditModalIsOpen,
      featureModalIsOpen,
      featureId,
      featureType,
      featureName,
      featurePaddockName,
      featurePaddockType,
      featureComplexPaddockType,
      featureGeometryType,
      enclosingPaddock,
      featurePoints,
      createNewDestination,
      featureKey,
      featureDirectLinkWaypointKeys,
      featureIsShiftDestination,
      featureEntryForDestinationId,
    } = this.state;

    const { containerHeight, containerWidth, userAdmin, map } = this.props;

    let optionsToRemoveForFarmers;
    if (featureGeometryType === GEOMETRY_TYPE_ENUM.POLYGON) {
      optionsToRemoveForFarmers = [GEOJSON_PROPERTY_TYPES.TROUGH];
    } else if (featureGeometryType === GEOMETRY_TYPE_ENUM.POINT) {
      optionsToRemoveForFarmers = [GEOJSON_PROPERTY_TYPES.DATUM];
    } else if (featureGeometryType === GEOMETRY_TYPE_ENUM.LINE_STRING) {
      optionsToRemoveForFarmers = [GEOJSON_PROPERTY_TYPES.GATE];
    }
    // filter options if not an admin user
    const featureTypeSelect = userAdmin
      ? this.getFeatureTypeOptions()
      : this.getFeatureTypeOptions().filter((item) => !optionsToRemoveForFarmers.includes(item.name));
    const typeOptions = userAdmin
      ? this.getFeatureTypeOptions()
          .map((item) => item.name)
          .join(', ')
      : featureTypeSelect[0]?.name;
    const pathFilterActive = map.featureCollection.features.some(
      (feature) => feature.properties.type === GEOJSON_PROPERTY_TYPES.EXIT_PATH,
    );
    const paddockNames = this.editableLayers != null ? this.getPaddockNames() : [];
    const raceWaypointKeys = this.editableLayers != null ? this.getRaceWaypointKeys() : [];

    return (
      <div>
        <Modal
          isOpen={noDeleteModalIsOpen}
          contentLabel="Delete Paused "
          style={modalStyles}
          shouldCloseOnOverlayClick
        >
          <p>One or more paddocks or waypoints with dependent features were restored</p>
          <p>(check filters for any hidden dependent features)</p>
          <button
            type="button"
            onClick={this.toggleNoDeleteModalClick}
          >
            OK
          </button>
        </Modal>
        <Modal
          isOpen={noEditModalIsOpen}
          contentLabel="Edit Paused "
          style={modalStyles}
          shouldCloseOnOverlayClick
        >
          <p>Edit paddock properties not available when paddock is filtered</p>
          <button
            type="button"
            onClick={this.toggleNoEditModalClick}
          >
            OK
          </button>
        </Modal>
        <EditFeatureModal
          isOpen={featureModalIsOpen}
          modalStyles={modalStyles}
          typeOptions={typeOptions}
          enclosingPaddock={enclosingPaddock}
          paddockNames={paddockNames}
          allDestinations={this.destinations}
          raceWaypointKeys={raceWaypointKeys}
          handleFeatureModalCancel={this.handleFeatureModalCancel}
          handleFeatureModalSave={this.handleFeatureModalSave}
          featureType={featureType}
          featureName={featureName}
          featureId={featureId}
          featureKey={featureKey}
          featurePaddockName={featurePaddockName}
          featurePaddockType={featurePaddockType}
          featureComplexPaddockType={featureComplexPaddockType}
          isShiftDestination={featureIsShiftDestination}
          entryForDestinationId={featureEntryForDestinationId}
          featureTypeSelect={featureTypeSelect}
          createNewDestination={createNewDestination}
          featureDirectLinkWaypointKeys={featureDirectLinkWaypointKeys}
          isFeaturePropsInvalid={this.isFeaturePropsInvalid({
            type: featureType,
            name: featureName,
            paddockName: featurePaddockName,
          })}
          handleFeatureModalEdit={this.handleFeatureModalEdit}
          handleFeatureModalTypeChange={this.handleFeatureModalTypeChange}
          handleFeatureModalNameChange={this.handleFeatureModalNameChange}
          featureNameChange={(newValue) => this.setState({ featureName: newValue })}
          handleFeatureModalPaddockNameChange={this.handleFeatureModalPaddockNameChange}
          setCreateNewDestination={this.setCreateNewDestination}
          isShiftDestinationChange={(newValue) => this.setState({ featureIsShiftDestination: newValue })}
          entryForDestinationIdChange={(newValue) => this.setState({ featureEntryForDestinationId: newValue })}
          featureDirectLinkWaypointKeysChange={(newValue) => this.setState({ featureDirectLinkWaypointKeys: newValue })}
          handleFeatureModalComplexPaddockTypeChange={this.handleFeatureModalComplexPaddockTypeChange}
          handleFeatureModalPaddockTypeChange={this.handleFeatureModalPaddockTypeChange}
          handleFeatureModalIsComplexPaddockChange={this.handleFeatureModalIsComplexPaddockChange}
        />
        <div
          id="map"
          style={{ height: `${containerHeight - HEADER_HEIGHT}px` }}
        />
        <div
          style={{
            zIndex: 999,
            position: 'absolute',
            top: pathFilterActive ? '382px' : '320px',
            left: '24px',
            color: 'white',
            maxHeight: `${containerHeight - HEADER_HEIGHT - 396}px`,
            overflowY: 'scroll',
            backgroundColor: 'rgb(20, 21, 24, 0.6)',
            borderRadius: '8px',
            width: '232px',
            display: this.state.areas.length > 0 ? 'block' : 'none',
            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.35)',
          }}
        >
          {this.renderAreas()}
        </div>
        <div
          className="render-points-div"
          style={{
            backgroundColor:
              featurePoints > MAXIMUM_ALLOWED_NUMBER_OF_COORDINATES_FOR_POLYGONS - 1 ? '#D82833' : 'black',
            display: this.state.featurePoints === 0 ? 'none' : 'block',
            left: containerWidth / 2 - 80,
          }}
        >
          {this.renderPoints()}
        </div>
        <div
          className="render-points-error-div"
          style={{
            display:
              this.state.featurePoints > MAXIMUM_ALLOWED_NUMBER_OF_COORDINATES_FOR_POLYGONS - 1 ? 'block' : 'none',
            left: containerWidth / 2 - 168,
          }}
        >
          You have exceeded the
          {MAXIMUM_ALLOWED_NUMBER_OF_COORDINATES_FOR_POLYGONS - 1}
          point limit - if you have any concerns drawing your paddock boundary please contact the team.
        </div>
        <ToolBarHelper display={this.state.showToolbarHelper} />
      </div>
    );
  }
}

MapLeaflet.propTypes = {
  containerHeight: PropTypes.number.isRequired,
  containerWidth: PropTypes.number.isRequired,
  mapSet: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  map: PropTypes.object.isRequired,
  userAdmin: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  map: state.map,
});

export default connect(mapStateToProps, { mapSet })(MapLeaflet);
