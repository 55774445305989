var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { push } from 'connected-react-router';
import _get from 'lodash/get';
import * as Notifications from 'react-notification-system-redux';
function notifyArgs(title, message) {
    if (message === void 0) { message = undefined; }
    var args = {
        title: title,
        position: 'tc',
        autoDismiss: 6,
        message: undefined,
    };
    if (message) {
        args.message = message;
    }
    return args;
}
export var notifySuccessDev = function (dispatch, title, message) {
    if (message === void 0) { message = undefined; }
    if (window.$NODE_ENV === 'development') {
        dispatch(Notifications.success(notifyArgs(title, message)));
    }
};
export var notifySuccess = function (dispatch, title, message) {
    if (message === void 0) { message = undefined; }
    dispatch(Notifications.success(notifyArgs(title, message)));
};
export var notifyError = function (dispatch, title, message) {
    if (message === void 0) { message = undefined; }
    dispatch(Notifications.error(notifyArgs(title, message)));
};
export var manageCatch = function (dispatch, res, failure) {
    var err = __assign({}, res).response;
    var errorMessage;
    var conflictErrorMessage = 'Features already saved or published elsewhere';
    var inconsistentState = 'Inconsistent State error, refresh and try again';
    if (window.$NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.log(err);
    }
    if (err) {
        if (err.status === 401) {
            notifyError(dispatch, err.statusText);
            dispatch(push('/logout'));
        }
        else if (err.status === 409) {
            notifyError(dispatch, err.statusText, _get(err, 'data.errors.message', conflictErrorMessage));
        }
        else if (err.status === 417) {
            notifyError(dispatch, err.statusText, _get(err, 'data.errors.message', inconsistentState));
        }
        else {
            if (err.config.url.includes('/draft-feature-collection')) {
                errorMessage = 'No draft features found';
            }
            else if (err.config.url.includes('/feature-collection')) {
                errorMessage = 'No features found';
            }
            notifyError(dispatch, err.statusText, _get(err, 'data.errors.message', errorMessage));
            if (failure) {
                dispatch(failure());
            }
        }
    }
};
