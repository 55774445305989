import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { NotesHistory } from './NotesHistory';
import { ToolbarButton } from './ToolbarButton';
export var ShowNotesButton = function (_a) {
    var farmName = _a.farmName, disabled = _a.disabled;
    var _b = useState(false), isShowingNotes = _b[0], setIsShowingNotes = _b[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(ToolbarButton, { icon: "caret-down", backgroundColor: "#374FC7", onClick: function () { return setIsShowingNotes(true); }, disabled: disabled }),
        React.createElement(Modal, { isOpen: isShowingNotes, size: "xl" },
            React.createElement(ModalHeader, null,
                farmName,
                " change log"),
            React.createElement(ModalBody, null,
                React.createElement(NotesHistory, null)),
            React.createElement(ModalFooter, null,
                React.createElement("div", { className: "d-flex flex-row" },
                    React.createElement("button", { type: "button", className: "btn btn-secondary", onClick: function () { return setIsShowingNotes(false); } }, "Close"))))));
};
