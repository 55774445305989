import React, { Component } from 'react';

import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

class Confirm extends Component {
  constructor(props) {
    super(props);
    const { visible } = this.props;
    this.state = {
      isOpened: !!visible,
      tooltipOpen: false,
      tooltipId: `confirm-${Math.floor(Date.now())}`,
    };
    this.onButtonClick = this.onButtonClick.bind(this);
    this.onToggle = this.onToggle.bind(this);

    this.onCancel = this.onCancel.bind(this);
    this.onMiddle = this.onMiddle.bind(this);
    this.onConfim = this.onConfim.bind(this);

    this.onTooltipToggle = this.onTooltipToggle.bind(this);
  }

  onTooltipToggle() {
    const { isOpened, tooltipOpen } = this.state;
    if (!isOpened) {
      this.setState({
        tooltipOpen: !tooltipOpen,
      });
    }
  }

  onButtonClick(e) {
    const { stopPropagation } = this.props;
    if (stopPropagation) {
      e.stopPropagation();
    }
    this.setState({
      isOpened: true,
      tooltipOpen: false,
    });
  }

  onToggle(e) {
    if (e) {
      e.stopPropagation();
    }
    this.setState({
      isOpened: false,
      tooltipOpen: false,
    });
  }

  onCancel(e) {
    e.stopPropagation();
    this.setState({
      isOpened: false,
      tooltipOpen: false,
    });
    const { onCancel } = this.props;
    if (onCancel) {
      onCancel(e);
    }
  }

  onMiddle(e) {
    e.stopPropagation();
    this.setState({
      isOpened: false,
      tooltipOpen: false,
    });
    const { onMiddle } = this.props;
    if (onMiddle) {
      onMiddle(e);
    }
  }

  onConfim(e) {
    e.stopPropagation();
    this.setState({
      isOpened: false,
      tooltipOpen: false,
    });
    const { onConfirm } = this.props;
    if (onConfirm) {
      onConfirm(e);
    }
  }

  renderCancelButton() {
    const { showCancelButton, cancelColor, cancelText } = this.props;
    return (
      showCancelButton && (
        <Button
          color={cancelColor}
          onClick={this.onCancel}
        >
          {cancelText}
        </Button>
      )
    );
  }

  renderMiddleButton() {
    const { showMiddleButton, dataId, middleId, middleColor, middleText } = this.props;
    return (
      showMiddleButton && (
        <Button
          id={middleId}
          data-id={dataId}
          color={middleColor}
          onClick={this.onMiddle}
        >
          {middleText}
        </Button>
      )
    );
  }

  renderConfirmButton() {
    const { dataId, confirmId, confirmColor, confirmText } = this.props;

    return (
      <Button
        id={confirmId}
        data-id={dataId}
        color={confirmColor}
        onClick={this.onConfim}
      >
        {confirmText}
      </Button>
    );
  }

  renderModal() {
    const { title, body } = this.props;

    const { isOpened } = this.state;

    return (
      <Modal
        isOpen={isOpened}
        toggle={this.onToggle}
      >
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>{body}</ModalBody>
        <ModalFooter>
          {this.renderCancelButton()}
          {this.renderMiddleButton()}
          {this.renderConfirmButton()}
        </ModalFooter>
      </Modal>
    );
  }

  renderDefault(modal) {
    const { buttonText, buttonColor } = this.props;

    return (
      <Button
        color={buttonColor}
        onClick={this.onButtonClick}
      >
        {buttonText}
        {modal}
      </Button>
    );
  }

  renderCustom(modal) {
    const { children, style, tip } = this.props;

    const { tooltipId } = this.state;
    const btn = React.Children.only(children);
    const content = React.cloneElement(
      btn,
      {
        id: tooltipId,
        onClick: this.onButtonClick,
        style,
      },
      btn.props.children,
      modal,
    );
    // TODO tips pop up again after modal closes.
    if (tip) {
      return content;
      // return (
      //   <span>
      //     {content}
      //     <Tooltip
      //       placement='top'
      //       isOpen={this.state.tooltipOpen}
      //       target={this.state.tooltipId}
      //       toggle={this.onTooltipToggle}
      //     >
      //       {this.props.tip}
      //     </Tooltip>
      //   </span>
      // )
    }
    return content;
  }

  render() {
    const modal = this.renderModal();
    const { children } = this.props;
    if (children) {
      return this.renderCustom(modal);
    }
    return this.renderDefault(modal);
  }
}

Confirm.propTypes = {
  children: PropTypes.node,
  style: stylePropType,
  visible: PropTypes.bool,
  title: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
  tip: PropTypes.node,
  stopPropagation: PropTypes.bool,
  buttonText: PropTypes.node,
  buttonColor: PropTypes.string,
  cancelText: PropTypes.node,
  cancelColor: PropTypes.string,
  confirmText: PropTypes.node,
  confirmColor: PropTypes.string,
  middleText: PropTypes.node,
  middleColor: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onMiddle: PropTypes.func,
  showCancelButton: PropTypes.bool,
  showMiddleButton: PropTypes.bool,
  dataId: PropTypes.string,
  middleId: PropTypes.string,
  confirmId: PropTypes.string,
};

Confirm.defaultProps = {
  children: null,
  style: null,
  visible: false,
  tip: null,
  stopPropagation: false,
  buttonText: 'Action',
  buttonColor: 'primary',
  confirmText: 'Confirm',
  confirmColor: 'danger',
  showCancelButton: true,
  onCancel: null,
  cancelText: 'Cancel',
  cancelColor: 'default',
  onMiddle: null,
  showMiddleButton: false,
  middleText: 'Middle',
  middleColor: 'default',
  dataId: null,
  middleId: null,
  confirmId: null,
};

export default Confirm;
