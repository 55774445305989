import React from 'react';
import moment from 'moment/moment';
import { extractInitialsFromEmail } from '../../../lib/extractInitialsFromEmail';
import { MappingChangeLog } from '../../../models/MappingChangeLog';
export var NotesHistory = function () {
    var _a;
    var data = MappingChangeLog.useFetchAll().data;
    var changeLog = (_a = data === null || data === void 0 ? void 0 : data.data) !== null && _a !== void 0 ? _a : [];
    return (React.createElement("div", null,
        React.createElement("table", { className: "table mb-0" },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", { colSpan: 2 }, "Change"),
                    React.createElement("th", { className: "text-right" }, "Time"))),
            React.createElement("tbody", null, changeLog.map(function (cl) { return (React.createElement("tr", { key: cl.id, title: "Modified by ".concat(cl.userId, " at ").concat(cl.timestamp.toLocaleString()) },
                React.createElement("td", null, extractInitialsFromEmail(cl.userId)),
                React.createElement("td", null,
                    React.createElement("div", null, cl.note)),
                React.createElement("td", { className: "text-right" },
                    React.createElement("small", { className: "text-nowrap" },
                        moment(cl.timestamp).format('YYYY-MM-DD'),
                        " "),
                    React.createElement("small", null,
                        React.createElement("strong", null, moment(cl.timestamp).format('HH:mm')))))); })))));
};
