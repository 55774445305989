import { GEOJSON_PROPERTY_TYPES } from '@halter-corp/geojson-feature-validator';
import { ComplexPaddockTypeEnum } from '@halter-corp/topography-service-client';
import _merge from 'lodash/merge';
export function setFeatureStyle(feature) {
    var setFeatureTypeStyle = function () {
        switch (feature.properties.type) {
            case GEOJSON_PROPERTY_TYPES.GATE:
                return { color: '#FF0000', weight: 8 };
            case GEOJSON_PROPERTY_TYPES.BEEF_GRAZING_GUIDELINE:
                return { color: '#FF0000', fill: false };
            case GEOJSON_PROPERTY_TYPES.TROUGH:
                return { color: '#37CDFA', fillColor: '#37CDFA', fillOpacity: '0.8' };
            case GEOJSON_PROPERTY_TYPES.IRRIGATED_AREA:
                return { color: '#374FC7', fillColor: '#374FC7' };
            case GEOJSON_PROPERTY_TYPES.EXIT_PATH:
                return { opacity: 0.5, fill: false };
            case GEOJSON_PROPERTY_TYPES.FARM_BOUNDARY:
                return { color: '#FFCF78', fill: false, interactive: false, weight: 1 };
            case GEOJSON_PROPERTY_TYPES.NON_HARVESTABLE_AREA:
                return { color: '#A1B0C4', fillColor: '#A1B0C4' };
            case GEOJSON_PROPERTY_TYPES.TREE:
                return { color: '#36CF25', fillColor: '#36CF25' };
            case GEOJSON_PROPERTY_TYPES.WATERWAY:
                return { color: '#257FF5', fillColor: '#257FF5' };
            case GEOJSON_PROPERTY_TYPES.PERMANENT_STRUCTURE:
                return { color: '#A1B0C4', fillColor: '#A1B0C4' };
            case GEOJSON_PROPERTY_TYPES.BUSH_BLOCK:
                return { color: '#36CF25', fillColor: '#36CF25' };
            case GEOJSON_PROPERTY_TYPES.POND:
                return { color: '#257FF5', fillColor: '#257FF5' };
            case GEOJSON_PROPERTY_TYPES.TRANSMISSION_TOWER:
                return { color: '#A1B0C4', fillColor: '#A1B0C4' };
            default:
                return {};
        }
    };
    var setPathColor = function () {
        switch (feature.properties.name) {
            case 'Milking Shed':
                return { color: 'DeepPink' };
            case 'Feed Pad':
                return { color: 'Blue' };
            case 'Crop Paddock':
                return { color: 'Red' };
            default:
                return {};
        }
    };
    return _merge({
        interactive: true,
        bubblingMouseEvents: false,
        stroke: true,
        color: '#ffa500',
        weight: 2,
        opacity: 1,
        fill: true,
        fillColor: '#ffa500',
        fillOpacity: 0.15,
        dashArray: '5,1',
    }, setFeatureTypeStyle(), setPathColor());
}
var suffixForComplexPaddocks = function (complexPaddockType) {
    if (complexPaddockType == null) {
        return '';
    }
    if (complexPaddockType === ComplexPaddockTypeEnum.HIGH_COMPLEXITY_PADDOCK) {
        return ' (high complexity)';
    }
    return ' (low complexity)';
};
export function setTooltipName(layer) {
    var _a = layer.feature.properties, name = _a.name, type = _a.type, paddockName = _a.paddockName, complexPaddockType = _a.complexPaddockType;
    if (type === GEOJSON_PROPERTY_TYPES.EXIT_POINT) {
        return "".concat(name, "<br/>Paddock: ").concat(paddockName);
    }
    else if (type === GEOJSON_PROPERTY_TYPES.TROUGH) {
        return "".concat(type, "<br/>Paddock: ").concat(paddockName);
    }
    if (name) {
        return "".concat(type, ": ").concat(name).concat(suffixForComplexPaddocks(complexPaddockType));
    }
    else if (type === GEOJSON_PROPERTY_TYPES.EXIT_POINT && paddockName) {
        return "".concat(type, ": ").concat(paddockName);
    }
    return type;
}
