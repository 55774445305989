import React, { Component } from 'react';

import _trim from 'lodash/trim';
import PropTypes from 'prop-types';

class ErrorBoundaryApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
    if (window.$NODE_ENV !== 'development') {
      try {
        const exception = {
          path: window.location.href,
          error: error ? error.toString() : 'error',
          errorInfo: errorInfo.componentStack
            .split('\n')
            .map((line) => _trim(line))
            .filter((line) => line),
        };
        if (window.Rollbar) {
          window.Rollbar.error('[Boundary error]', JSON.stringify(exception));
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('Boundary error', err, error, errorInfo);
      }
    }
  }

  render() {
    const { error, errorInfo } = this.state;

    const { containerHeight, children } = this.props;

    if (error) {
      if (window.$NODE_ENV === 'development') {
        return (
          <div>
            <div>
              <h2>{error && error.toString()}</h2>
              <pre>{errorInfo.componentStack}</pre>
            </div>
          </div>
        );
      }
      return (
        <div style={{ height: `${containerHeight}px`, marginTop: 0 }}>
          <div style={{ height: '100%' }}>
            <div className="error-container">
              <div
                style={{
                  display: 'table',
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                }}
              >
                <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                  <div style={{ margin: '0 auto', width: '300px' }}>
                    <h2>Something went wrong</h2>
                    <br />
                    <p>This error has been logged and we&apos;ll get to it shortly.</p>
                    <br />
                    <a
                      className="btn btn-primary btn-block"
                      href={window.location.href}
                    >
                      Try Again
                    </a>
                    <br />
                    <a
                      className="btn btn-white btn-block"
                      href="/"
                    >
                      Home
                    </a>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return children;
  }
}

ErrorBoundaryApp.propTypes = {
  containerHeight: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default ErrorBoundaryApp;
