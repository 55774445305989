import React from 'react';
var startingTopHeight = 78;
function ToolBarHelper(_a) {
    var display = _a.display;
    var _b = display[0], displayType = _b === void 0 ? 'block' : _b, _c = display[1], componentName = _c === void 0 ? '' : _c;
    var helperName = function () {
        switch (componentName) {
            case 'leaflet-draw-draw-polyline':
                return ['Add a path', "".concat(startingTopHeight, "px")];
            case 'leaflet-draw-draw-polygon':
                return ['Add polygon (e.g. paddock)', "".concat(startingTopHeight + 46, "px")];
            case 'leaflet-draw-draw-circle':
                return ['Add trough', "".concat(startingTopHeight + 92, "px")];
            case 'leaflet-draw-draw-marker':
                return ['Add point', "".concat(startingTopHeight + 138, "px")];
            case 'leaflet-draw-edit-edit':
                return ['Edit features', "".concat(startingTopHeight + 190, "px")];
            case 'leaflet-draw-edit-remove':
                return ['Delete feature', "".concat(startingTopHeight + 236, "px")];
            default:
                return ['', ''];
        }
    };
    var _d = helperName(), helperText = _d[0], positionFromTop = _d[1];
    return (React.createElement("div", { id: "drawHelper", style: {
            zIndex: 999,
            top: positionFromTop,
            borderRadius: '8px',
            height: '32px',
            position: 'absolute',
            right: '78px',
            backgroundColor: '#141518',
            color: 'white',
            border: '1px solid #141518',
            textAlign: 'center',
            verticalAlign: 'middle',
            display: helperText ? displayType : 'none',
            padding: '4px',
            paddingLeft: '8px',
            paddingRight: '8px',
        } }, helperText));
}
export default ToolBarHelper;
