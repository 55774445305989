export var apiUrl = process.env.API_URL;
export var tilesHost = process.env.TILES_HOST;
export var intercomAppId = process.env.INTERCOM_APP_ID;
export var intercomHelpUrl = process.env.INTERCOM_HELP_URL;
export var appEnvironment = process.env.REACT_APP_ENVIRONMENT;
export var initialMapCoodinates = process.env.INITIAL_MAP_COORDINATES
    ? JSON.parse(process.env.INITIAL_MAP_COORDINATES)
    : {
        lat: -37.78333,
        lng: 175.28333,
        zoom: 10,
    };
export var awsAuthConfig = {
    region: process.env.AWS_REGION,
    userPoolId: process.env.AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.AWS_USER_POOL_WEB_CLIENT_ID,
};
