/* eslint-disable jsx-a11y/label-has-associated-control */
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EXIT_PATH_NAME_OPTIONS } from '@halter-corp/geojson-feature-validator';
import _values from 'lodash/values';
var ENTRY_NAMES = ['North', 'East', 'South', 'West', 'Northeast', 'Southeast', 'Southwest', 'Northwest'];
export function WaypointEditor(_a) {
    var featureKey = _a.featureKey, featureName = _a.featureName, featureNameChange = _a.featureNameChange, featureDirectLinkWaypointKeys = _a.featureDirectLinkWaypointKeys, featureDirectLinkWaypointKeysChange = _a.featureDirectLinkWaypointKeysChange, featureIsShiftDestination = _a.featureIsShiftDestination, featureIsShiftDestinationChange = _a.featureIsShiftDestinationChange, featureEntryForDestinationId = _a.featureEntryForDestinationId, featureEntryForDestinationIdChange = _a.featureEntryForDestinationIdChange, isNewDestinationName = _a.isNewDestinationName, isNewDestinationNameChange = _a.isNewDestinationNameChange, allDestinations = _a.allDestinations, raceWaypointKeys = _a.raceWaypointKeys;
    var handleWaypointTypeChange = function (e, newType) {
        var _a, _b, _c;
        e.preventDefault();
        if (featureIsShiftDestination && newType !== 'SHIFT_DESTINATION') {
            featureIsShiftDestinationChange(false);
        }
        else if (newType === 'SHIFT_DESTINATION') {
            featureIsShiftDestinationChange(true);
        }
        if (featureEntryForDestinationId != null && newType !== 'DESTINATION_ENTRY') {
            featureEntryForDestinationIdChange(null);
        }
        else if (newType === 'DESTINATION_ENTRY') {
            featureEntryForDestinationIdChange((_c = (_b = (_a = allDestinations[0]) === null || _a === void 0 ? void 0 : _a.properties) === null || _b === void 0 ? void 0 : _b.id) !== null && _c !== void 0 ? _c : null);
            if (!ENTRY_NAMES.includes(featureName)) {
                featureNameChange(ENTRY_NAMES[0]);
            }
        }
    };
    var handleFeatureModalNameChange = function (e) {
        var newFeatureName = e.currentTarget.value;
        if (newFeatureName === '') {
            isNewDestinationNameChange(true);
        }
        featureNameChange(newFeatureName);
    };
    var handleFeatureDirectWaypointLinksChange = function (key, e) {
        if (e.currentTarget.checked) {
            featureDirectLinkWaypointKeysChange(__spreadArray(__spreadArray([], featureDirectLinkWaypointKeys, true), [key], false));
        }
        else {
            featureDirectLinkWaypointKeysChange(featureDirectLinkWaypointKeys.filter(function (k) { return k !== key; }));
        }
    };
    return (React.createElement("div", null,
        React.createElement("div", { className: "row" },
            React.createElement("label", { className: "col-form-label col-4", htmlFor: "race-waypoint-key-input" }, "Key"),
            React.createElement("div", { className: "col-8" },
                React.createElement("input", { className: "form-control", id: "race-waypoint-key-input", value: featureKey, placeholder: "Type here", disabled: true }))),
        React.createElement("div", { className: "col-form-label" }, "Links to"),
        React.createElement("div", { style: {
                width: '400px',
                height: '120px',
                overflowY: 'auto',
                border: '1px solid gray',
            } },
            React.createElement("div", { className: "row" }, raceWaypointKeys
                .filter(function (key) { return key !== featureKey; })
                .map(function (key) { return (React.createElement("div", { className: "col-3", key: key },
                React.createElement("div", { className: "form-check" },
                    React.createElement("input", { type: "checkbox", id: "raceway-".concat(key), checked: featureDirectLinkWaypointKeys.includes(key), onChange: function (e) { return handleFeatureDirectWaypointLinksChange(key, e); }, className: "form-check-input" }),
                    React.createElement("label", { htmlFor: "raceway-".concat(key), className: "form-check-label" }, key)))); }))),
        React.createElement("div", { className: "col-form-label mt-3" }, "Waypoint type"),
        React.createElement("ul", { className: "nav nav-pills mb-2" },
            React.createElement("li", { className: "nav-item" },
                React.createElement("a", { href: "#NORMAL", className: "nav-link ".concat(!featureIsShiftDestination && featureEntryForDestinationId == null ? 'active' : ''), onClick: function (e) { return handleWaypointTypeChange(e, 'NORMAL'); } }, "Normal")),
            React.createElement("li", { className: "nav-item" },
                React.createElement("a", { href: "#SHIFT_DESTINATION", className: "nav-link ".concat(featureIsShiftDestination ? 'active' : ''), onClick: function (e) { return handleWaypointTypeChange(e, 'SHIFT_DESTINATION'); } }, "Destination")),
            React.createElement("li", { className: "nav-item" },
                React.createElement("a", { href: "#DESTINATION_ENTRY", className: "nav-link ".concat(featureEntryForDestinationId != null ? 'active' : ''), onClick: function (e) { return handleWaypointTypeChange(e, 'DESTINATION_ENTRY'); } }, "Destination entry"))),
        React.createElement("div", { className: "tab-content" },
            React.createElement("div", { className: "tab-pane fade ".concat(featureIsShiftDestination ? 'show active' : '') },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-form-label col-4" }, "Name"),
                    React.createElement("div", { className: "col-8" }, isNewDestinationName ? (React.createElement("div", { className: "input-group" },
                        React.createElement("input", { className: "form-control", id: "race-waypoint-other-shift-destination-option", value: featureName, placeholder: "Type here", onChange: handleFeatureModalNameChange }),
                        featureName.length > 0 && (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                        React.createElement("div", { className: "input-group-append", onClick: function () { return isNewDestinationNameChange(false); } },
                            React.createElement("div", { className: "input-group-text" },
                                React.createElement(FontAwesomeIcon, { icon: "times" })))))) : (React.createElement("select", { id: "feature-name", value: featureName, onChange: handleFeatureModalNameChange, className: "custom-select" },
                        React.createElement("option", { key: "default", value: "" }, "select ..."),
                        _values(EXIT_PATH_NAME_OPTIONS).map(function (name) { return (React.createElement("option", { key: name, value: name }, name)); }),
                        React.createElement("option", { key: "spacer", disabled: true }),
                        React.createElement("option", { key: "create-new", value: "" }, "Create new ...")))))),
            React.createElement("div", { className: "tab-pane fade ".concat(featureEntryForDestinationId != null ? 'show active' : '') },
                React.createElement("div", { className: "row form-group" },
                    React.createElement("label", { className: "col-form-label col-4", htmlFor: "featureEntryForDestinationId" }, "Destination"),
                    React.createElement("div", { className: "col-8" }, featureEntryForDestinationId != null && (React.createElement("select", { id: "featureEntryForDestinationId", className: "custom-select", onChange: function (e) { return featureEntryForDestinationIdChange(e.target.value); }, value: featureEntryForDestinationId }, allDestinations.map(function (destination) { return (React.createElement("option", { key: destination.properties.id, value: destination.properties.id }, destination.properties.name)); }))))),
                React.createElement("div", { className: "row form-group" },
                    React.createElement("label", { className: "col-form-label col-4", htmlFor: "entryName" }, "Name"),
                    React.createElement("div", { className: "col-8" },
                        React.createElement("select", { className: "custom-select", value: featureName, onChange: handleFeatureModalNameChange, id: "entryName" }, ENTRY_NAMES.map(function (entryName) { return (React.createElement("option", { key: entryName, value: entryName }, entryName)); }))))))));
}
