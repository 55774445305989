function stripDomain(email) {
    if (email.indexOf('@') > 0)
        return email.split('@')[0];
    return email;
}
export function extractInitialsFromEmail(email) {
    if (email === 'unknown')
        return '?';
    var username = stripDomain(email);
    // Split the username by non-alphabetic characters and filter out empty strings
    var usernameParts = username.split(/[^a-zA-Z]+/).filter(function (part) { return part !== ''; });
    if (usernameParts.length <= 1)
        return username.substring(0, 3);
    // Extract initials from username parts
    return usernameParts.map(function (part) { return part.charAt(0).toUpperCase(); }).join('');
}
