var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { actionTypes } from '../constants';
import { fetch, reset, unfetch } from '../lib/reducer_helpers';
export var initialState = {
    isFetching: false,
    model: {},
};
export default function draftFeatureCollectionReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var type = action.type, payload = action.payload;
    switch (type) {
        case actionTypes.DRAFT_FEATURE_COLLECTION_RESET:
            return reset(initialState);
        case actionTypes.DRAFT_FEATURE_COLLECTION_FETCH:
            return fetch(state);
        case actionTypes.DRAFT_FEATURE_COLLECTION_UNFETCH:
            return unfetch(state);
        case actionTypes.DRAFT_FEATURE_COLLECTION_LOAD_SUCCESS:
            return __assign(__assign({}, state), { model: payload, isFetching: false });
        case actionTypes.DRAFT_FEATURE_COLLECTION_LOAD_FAILURE:
            return __assign({}, initialState);
        case actionTypes.DRAFT_FEATURE_COLLECTION_CREATE_SUCCESS:
            return __assign(__assign({}, state), { model: payload, isFetching: false });
        case actionTypes.DRAFT_FEATURE_COLLECTION_CREATE_FAILURE:
            return __assign({}, initialState);
        default:
            return state;
    }
}
