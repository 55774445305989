/* eslint-disable */
/*
 * L.Control.ZoomDisplay shows the current map zoom level
 */

L.Control.ZoomDisplay = L.Control.extend({
  options: {
    position: 'bottomright',
  },

  onAdd(map) {
    this._map = map;
    this._container = L.DomUtil.create('div', 'leaflet-control-zoom-display leaflet-bar-part leaflet-bar');
    this.updateMapZoom(map.getZoom());
    map.on('zoomend', this.onMapZoomEnd, this);
    return this._container;
  },

  onRemove(map) {
    map.off('zoomend', this.onMapZoomEnd, this);
  },

  onMapZoomEnd(e) {
    this.updateMapZoom(this._map.getZoom());
  },

  updateMapZoom(zoom) {
    if (typeof zoom === 'undefined') {
      zoom = '';
    }
    this._container.innerHTML = zoom;
  },
});

L.Map.mergeOptions({
  zoomDisplayControl: true,
});

L.Map.addInitHook(function () {
  if (this.options.zoomDisplayControl) {
    this.zoomDisplayControl = new L.Control.ZoomDisplay();
    this.addControl(this.zoomDisplayControl);
  }
});

L.control.zoomDisplay = function (options) {
  return new L.Control.ZoomDisplay(options);
};
