import mirrorCreator from 'mirror-creator';
export var actionTypes = mirrorCreator([
    'FARM_RESET',
    'FARM_FETCH',
    'FARM_UNFETCH',
    'FARM_LOAD_SUCCESS',
    'FARM_LOAD_FAILURE',
    'FARM_DESTROY_SUCCESS',
    'FARM_DESTROY_FAILURE',
    'FARM_FILTER_SUCCESS',
    'FARM_SET',
    'FARM_UNSET',
    'FARM_CREATE_SUCCESS',
    'FARM_CREATE_FAILURE',
    'FARM_UPDATE_SUCCESS',
    'FARM_UPDATE_FAILURE',
    'MAP_SET',
    'MAP_RESET',
    'FEATURE_COLLECTION_RESET',
    'FEATURE_COLLECTION_FETCH',
    'FEATURE_COLLECTION_UNFETCH',
    'FEATURE_COLLECTION_LOAD_SUCCESS',
    'FEATURE_COLLECTION_LOAD_FAILURE',
    'FEATURE_COLLECTION_CREATE_SUCCESS',
    'FEATURE_COLLECTION_CREATE_FAILURE',
    'DRAFT_FEATURE_COLLECTION_RESET',
    'DRAFT_FEATURE_COLLECTION_FETCH',
    'DRAFT_FEATURE_COLLECTION_UNFETCH',
    'DRAFT_FEATURE_COLLECTION_LOAD_SUCCESS',
    'DRAFT_FEATURE_COLLECTION_LOAD_FAILURE',
    'DRAFT_FEATURE_COLLECTION_CREATE_SUCCESS',
    'DRAFT_FEATURE_COLLECTION_CREATE_FAILURE',
    'DRAFT_FEATURE_COLLECTION_DELETE_SUCCESS',
    'DRAFT_FEATURE_COLLECTION_DELETE_FAILURE',
]);
export var GOOGLE_LAYER_TYPES = {
    ROADMAP: 'roadmap',
    SATELLITE: 'satellite',
    TERRAIN: 'terrain',
    HYBRID: 'hybrid',
};
export var MAPBOX_LAYER_TYPES = {
    STREETS: 'mapbox.streets',
    SATELLITE: 'mapbox.satellite',
};
export var GEOMETRY_TYPE_ENUM = {
    POINT: 'Point',
    POLYGON: 'Polygon',
    LINE_STRING: 'LineString',
};
export var FEATURE_COLLECTION_RESULT_ENUM = {
    DRAFT_FEATURE_COLLECTION: 'draftFeatures',
    FEATURE_COLLECTION: 'liveFeatures',
    NO_FEATURE_COLLECTION: 'noFeatures',
};
