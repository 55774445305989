import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ConnectedRouter } from 'connected-react-router';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/es/integration/react';
import 'whatwg-fetch';
import Loader from './components/loader';
import App from './containers/app';
import { AxiosProvider } from './lib/AxiosContext';
import { ReactQueryService } from './lib/ReactQuery.service';
import { history, persistor, store } from './store';
var rootEl = document.getElementById('root');
var renderApp = function (Component) {
    var queryClient = ReactQueryService.getQueryClient();
    return (React.createElement(AppContainer, null,
        React.createElement(AxiosProvider, null,
            React.createElement(QueryClientProvider, { client: queryClient },
                React.createElement(Provider, { store: store },
                    React.createElement(PersistGate, { loading: React.createElement(Loader, null), persistor: persistor },
                        React.createElement(ConnectedRouter, { history: history },
                            React.createElement(Route, { path: "/", component: Component }))))))));
};
render(renderApp(App), rootEl);
if (module.hot) {
    module.hot.accept('./containers/app', function () {
        // eslint-disable-next-line global-require
        var NextApp = require('./containers/app').default;
        render(renderApp(NextApp), rootEl);
    });
}
