var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { actionTypes } from '../constants';
import { initialMapCoodinates } from '../env-exports';
export var defaultFilterFeatures = [
    'paddock',
    'break-template',
    'exit-point',
    'exit-path',
    'gate',
    'trough',
    'centerline',
    'beef-grazing-guideline',
    'datum',
    'viewcenter',
    'race-waypoint',
];
export var initialState = __assign(__assign({}, initialMapCoodinates), { defaultLat: initialMapCoodinates.lat, defaultLng: initialMapCoodinates.lng, baseLayerIndex: 0, maxNativeZoom: 18, minZoom: 7, maxZoom: 22, featureCollectionSourceId: null, featureCollection: {
        type: 'FeatureCollection',
        features: [],
    }, rebased: true, persisted: true, lastUpdatedDate: '', filter: { filterFeatures: defaultFilterFeatures, filterPaddockName: '' } });
function rehydrate(state, payload) {
    return payload && Object.keys(payload).length > 0 && Object.keys(payload).includes('map')
        ? __assign(__assign({}, initialState), payload.map) : initialState;
}
export default function mapReducer(state, action) {
    if (state === void 0) { state = initialState; }
    var type = action.type, payload = action.payload, error = action.error;
    if (error) {
        return state;
    }
    switch (type) {
        case actionTypes.FEATURE_COLLECTION_LOAD_SUCCESS:
            return __assign(__assign({}, state), { rebased: false, persisted: true, featureCollection: payload.featureCollection });
        case actionTypes.DRAFT_FEATURE_COLLECTION_LOAD_SUCCESS:
            return __assign(__assign({}, state), { rebased: false, persisted: true, featureCollection: payload.draftFeatureCollection });
        case actionTypes.MAP_RESET:
            return initialState;
        case actionTypes.MAP_SET:
            return __assign(__assign({}, state), payload);
        case 'persist/REHYDRATE':
            return rehydrate(state, payload);
        default:
            return state;
    }
}
