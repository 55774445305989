import { GEOJSON_PROPERTY_TYPES } from '@halter-corp/geojson-feature-validator';
import { PaddockTypeEnum } from '@halter-corp/topography-service-client';
import { GEOMETRY_TYPE_ENUM } from '../constants';
export function processMapFile(data) {
    var paddockName = 0;
    var featureWithPadockMetadata = data.features.map(function (feature) {
        var updatedFeature = feature;
        if (updatedFeature.geometry.type === GEOMETRY_TYPE_ENUM.POLYGON) {
            // if there are no properties for the polygon feature
            if (Object.keys(updatedFeature.properties).length === 0) {
                updatedFeature.properties = {
                    paddockType: PaddockTypeEnum.NORMAL,
                    type: GEOJSON_PROPERTY_TYPES.PADDOCK,
                    name: paddockName.toString(),
                    paddockName: paddockName.toString(),
                };
                // if the properties for the polygon feature contain a name property
            }
            else if (Object.keys(updatedFeature.properties).includes('name')) {
                updatedFeature.properties = {
                    paddockType: PaddockTypeEnum.NORMAL,
                    type: GEOJSON_PROPERTY_TYPES.PADDOCK,
                    paddockName: updatedFeature.properties.name.toString(),
                    name: updatedFeature.properties.name.toString(),
                };
            }
        }
        paddockName += 1;
        return updatedFeature;
    });
    return { type: data.type, features: featureWithPadockMetadata };
}
