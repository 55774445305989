import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
// @ts-expect-error is a svg file
import exportFile from '../assets/download-icon.svg';
import '../css/farmer-download-panel.css';
function FarmerDownloadPanel(_a) {
    var handleExportFeatureCollectionClick = _a.handleExportFeatureCollectionClick;
    return (React.createElement("div", { id: "farmer-menu", style: {
            display: 'block',
            top: '16px',
            left: '24px',
            position: 'absolute',
            width: '232px',
            backgroundColor: 'rgb(20, 21, 24, 0.6)',
            borderRadius: '8px',
            height: '56px',
        } },
        React.createElement(Button, { type: "button", className: "farmer-option-button", onClick: handleExportFeatureCollectionClick, title: "Download File" },
            React.createElement("img", { alt: "Download", src: exportFile, style: { marginRight: '16px', filter: 'invert(100%)' } }),
            "Download File")));
}
FarmerDownloadPanel.propTypes = {
    handleExportFeatureCollectionClick: PropTypes.func.isRequired,
};
export default FarmerDownloadPanel;
