var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { MappingChangeLog } from '../../../models/MappingChangeLog';
import { NotesHistory } from './NotesHistory';
import { ToolbarButton } from './ToolbarButton';
export var SaveFarmButton = function (_a) {
    var farmName = _a.farmName, userEmail = _a.userEmail, onSave = _a.onSave, disabled = _a.disabled;
    var _b = useState(false), isShowingNotes = _b[0], setIsShowingNotes = _b[1];
    var _c = useState(''), note = _c[0], setNote = _c[1];
    var createNewLogItemMutation = MappingChangeLog.useCreateNew();
    var handleNoteChange = function (e) { return setNote(e.target.value); };
    var handleSave = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    onSave();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, , 3, 4]);
                    return [4 /*yield*/, createNewLogItemMutation.mutateAsync({
                            data: {
                                note: note,
                                userId: userEmail,
                            },
                        })];
                case 2:
                    _a.sent();
                    setNote('');
                    return [3 /*break*/, 4];
                case 3:
                    setIsShowingNotes(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement(ToolbarButton, { icon: "save", backgroundColor: "#374FC7", onClick: function () { return setIsShowingNotes(true); }, disabled: disabled === true || isShowingNotes }),
        React.createElement(Modal, { isOpen: isShowingNotes },
            React.createElement(ModalHeader, null,
                React.createElement("div", null,
                    "Save ",
                    React.createElement("strong", null, farmName))),
            React.createElement(ModalBody, null,
                React.createElement("textarea", { placeholder: "Optional notes (eg. what have you changed and why?)", className: "form-control", onChange: handleNoteChange }),
                React.createElement("div", { className: "d-flex flex-row mt-2 mb-5" },
                    React.createElement("button", { type: "button", className: "btn btn-outline-secondary", onClick: function () { return setIsShowingNotes(false); } }, "Cancel"),
                    React.createElement("button", { type: "button", className: "btn btn-success ml-auto", onClick: handleSave }, "Save changes")),
                React.createElement("div", { style: { maxHeight: 400, overflow: 'scroll' } },
                    React.createElement(NotesHistory, null))))));
};
