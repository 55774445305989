import React, { Component } from 'react';

import { library as faLibrary } from '@fortawesome/fontawesome-svg-core';
import {
  faBars,
  faCaretDown,
  faCheck,
  faExclamation,
  faHome,
  faInfoCircle,
  faPlus,
  faQuestion,
  faSave,
  faSpinner,
  faThumbtack,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import Amplify from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';
import Dimensions from 'react-dimensions';
import Notifications from 'react-notification-system-redux';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import { compose } from 'redux';

import HalterIcon from '../assets/halter-logo.png';
import ErrorBoundaryApp from '../components/error_boundary_app';
import { awsAuthConfig } from '../env-exports';
import { getCurrentUser, isHalterEmail } from '../lib/account-helper';
import MapShow from './MapShow';
import HelpCenter from './help_center';

// bootstrap
require('bootstrap/dist/css/bootstrap.css');

// leaflet polyline measure
require('leaflet.polylinemeasure/Leaflet.PolylineMeasure.css');

// local
require('../css/local.css');

faLibrary.add(faSpinner);
faLibrary.add(faHome);
faLibrary.add(faExclamation);
faLibrary.add(faThumbtack);
faLibrary.add(faPlus);
faLibrary.add(faBars);
faLibrary.add(faInfoCircle);
faLibrary.add(faCaretDown);
faLibrary.add(faTimes);
faLibrary.add(faCheck);
faLibrary.add(faQuestion);
faLibrary.add(faSave);

Amplify.configure({
  Auth: awsAuthConfig,
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: null,
    };
    this.renderMapShow = this.renderMapShow.bind(this);
    this.loadUserInfo = this.loadUserInfo.bind(this);
    this.renderHelpCenter = this.renderHelpCenter.bind(this);
  }

  loadUserInfo() {
    getCurrentUser().then((user) => {
      this.setState({
        userInfo: user,
      });
    });
  }

  renderMapShow(props) {
    const { containerWidth, containerHeight, authState } = this.props;
    let userAdmin;
    let authFarmId;
    let userEmail;
    let userFarms;
    const { userInfo } = this.state;
    if (userInfo == null) {
      this.loadUserInfo();
    } else {
      userEmail = userInfo.email;
      userAdmin = isHalterEmail(userEmail);
      authFarmId = userInfo['custom:farmId'];
      // if they're an admin user we don't need the list of farms
      userFarms = userAdmin ? [] : JSON.parse(userInfo['custom:farms']);
    }

    if (authFarmId == null || userAdmin == null) {
      return null;
    } else {
      return (
        <MapShow
          containerWidth={containerWidth}
          containerHeight={containerHeight}
          authState={authState}
          authFarmId={authFarmId}
          userAdmin={userAdmin}
          userEmail={userEmail}
          userFarms={userFarms}
          {...props}
        />
      );
    }
  }

  renderHelpCenter() {
    let userEmail;
    let userHash;
    let userName;
    const { userInfo } = this.state;
    if (userInfo == null) {
      this.loadUserInfo();
    } else {
      userEmail = userInfo.email;
      userHash = JSON.parse(userInfo['custom:intercom']).hmac.web;
      userName = userInfo.given_name;
    }
    // assumed to limit to currently authorised users only
    if (userEmail == null || userHash == null) {
      return null;
    } else {
      return (
        <HelpCenter
          userEmail={userEmail}
          userHash={userHash}
          userName={userName}
        />
      );
    }
  }

  renderNotifications() {
    const { notifications } = this.props;
    return <Notifications notifications={notifications} />;
  }

  render() {
    const { containerHeight } = this.props;
    return (
      <ErrorBoundaryApp containerHeight={containerHeight}>
        <div>
          <div style={{ height: '64px', color: 'white', backgroundColor: 'black' }}>
            <img
              src={HalterIcon}
              style={{ marginLeft: '27px', marginTop: '24px' }}
              alt="HalterIcon"
            />
          </div>
          <Container fluid>
            <Switch>
              <Route
                path="/map"
                render={this.renderMapShow}
              />
              <Route
                path="/help"
                render={this.renderHelpCenter}
              />
            </Switch>
          </Container>
          {this.renderNotifications()}
        </div>
      </ErrorBoundaryApp>
    );
  }
}

function mapStateToProps(state) {
  return {
    notifications: state.notifications,
  };
}

export default compose(Dimensions(), connect(mapStateToProps, {}), withAuthenticator)(App);
