export function wrapAllFeatures(featureCollection) {
    var wrapLongitude = function (longitude) { return ((((longitude + 180) % 360) + 360) % 360) - 180; };
    var processCoordinates = function (coords) {
        if (Array.isArray(coords[0])) {
            // If the first element is an array (e.g. MultiPolygon), process each sub-array
            return coords.map(processCoordinates);
        }
        else {
            if (coords[0] < -180 || coords[0] > 180) {
                var wrappedLongitude = wrapLongitude(coords[0]);
                return [wrappedLongitude, coords[1]];
            }
            return coords;
        }
    };
    var newFeatureCollection = {
        type: 'FeatureCollection',
        features: featureCollection.features.map(function (feature) {
            if (feature.geometry && feature.geometry.coordinates) {
                feature.geometry.coordinates = processCoordinates(feature.geometry.coordinates);
            }
            return feature;
        }),
    };
    return newFeatureCollection;
}
