import draftFeatureCollectionReducer, { initialState as draftFeatureCollectionInitialState, } from './draft_feature_collection_reducer';
import farmsReducer, { initialState as farmsInitialState } from './farms_reducer';
import featureCollectionReducer, { initialState as featureCollectionInitialState } from './feature_collection_reducer';
import mapReducer, { initialState as mapInitialState } from './map_reducer';
export var reducers = {
    farms: farmsReducer,
    map: mapReducer,
    featureCollection: featureCollectionReducer,
    draftFeatureCollection: draftFeatureCollectionReducer,
};
export var initialState = {
    farms: farmsInitialState,
    map: mapInitialState,
    featureCollection: featureCollectionInitialState,
    draftFeatureCollection: draftFeatureCollectionInitialState,
};
