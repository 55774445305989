import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GFV, { GEOJSON_PROPERTY_TYPES, allPropertyTypes } from '@halter-corp/geojson-feature-validator';
import { ComplexPaddockTypeEnum } from '@halter-corp/topography-service-client';
import turfBuffer from '@turf/buffer';
import turfCenter from '@turf/center';
import { polygon } from '@turf/turf';
import { Auth } from 'aws-amplify';
import saveAs from 'file-saver';
import GJV from 'geojson-validation';
import _cloneDeep from 'lodash/cloneDeep';
import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _indexOf from 'lodash/indexOf';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { Button, ButtonDropdown, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import {
  draftFeatureCollectionCreate,
  draftFeatureCollectionLoad,
  draftFeatureCollectionReset,
} from '../../actions/draft_feature_collection_actions';
import { farmsLoad } from '../../actions/farm_actions';
import {
  featureCollectionCreate,
  featureCollectionLoad,
  featureCollectionReset,
} from '../../actions/feature_collection_actions';
import { mapReset, mapSet } from '../../actions/map_actions';
import exportFile from '../../assets/download-icon.svg';
import importFile from '../../assets/file-upload.svg';
import homeIcon from '../../assets/home.svg';
import logoutIcon from '../../assets/logout-icon.svg';
import publishIcon from '../../assets/publish-icon.svg';
import riverIcon from '../../assets/river-icon.svg';
import waterIcon from '../../assets/water-icon.svg';
import Confirm from '../../components/confirm';
import { FarmListPicker } from '../../components/farm-list-picker';
import FarmerDownloadPanel from '../../components/farmer-download-panel';
import { ImportFarmFeaturesModal } from '../../components/import-farm-features-model';
import IssuesModal from '../../components/issues-modal';
import { FEATURE_COLLECTION_RESULT_ENUM } from '../../constants';
import { intercomHelpUrl } from '../../env-exports';
import { canImportFeaturesFromOtherFarms, inWhiteList } from '../../lib/account-helper';
import { consolidateFeatures } from '../../lib/consolidate-features-for-multiple-farms';
import { wrapAllFeatures } from '../../lib/geojson-helper';
import { processMapFile } from '../../lib/process-file';
import { generateWaterwayFeatures } from '../../lib/waterway-helper';
import { defaultFilterFeatures } from '../../reducers/map_reducer';
import { SaveFarmButton } from './components/SaveFarmButton';
import { ShowNotesButton } from './components/ShowNotesButton';
import { ToolbarButton } from './components/ToolbarButton';

Modal.setAppElement(document.getElementById('root'));

const modalStyles = {
  overlay: {
    zIndex: '2000',
    backgroundColor: 'rgb(20, 21, 24, 0.6)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '8px',
    width: '700px',
  },
};

class MapToolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterFeatureById: '',
      featureDropdownOpen: false,
      paddockNameDropdownOpen: false,
      pathFilterDropDown: false,
      importModalIsOpen: false,
      importFromFarmModalIsOpen: false,
      importFile: '',
      importErrors: [],
      menuOpen: false,
      publishModalOpen: false,
      draftEditing: null,
      setAddressOpen: true,
      validationOn: true,
      showErrors: true,
      isPublishingFarm: false,
      farmsToImportFeaturesFrom: '',
    };

    this.toggleFeatureOpenClick = this.toggleFeatureOpenClick.bind(this);
    this.togglePaddockNameOpenClick = this.togglePaddockNameOpenClick.bind(this);
    this.togglePathFilterOpenClick = this.togglePathFilterOpenClick.bind(this);
    this.toggleMenuOpen = this.toggleMenuOpen.bind(this);
    this.toggleDraftEditing = this.toggleDraftEditing.bind(this);

    this.handleClearErrorsClick = this.handleClearErrorsClick.bind(this);

    this.openConfirmPublishModal = this.openConfirmPublishModal.bind(this);
    this.closeConfirmPublishModal = this.closeConfirmPublishModal.bind(this);
    this.closeSetAddress = this.closeSetAddress.bind(this);

    this.handleLogoutClick = this.handleLogoutClick.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);
    this.handleSaveFeatures = this.handleSaveFeatures.bind(this);
    this.handleSaveDraftFeatures = this.handleSaveDraftFeatures.bind(this);
    this.handleChangeFarmId = this.handleChangeFarmId.bind(this);
    this.handleUpdateLocationClick = this.handleUpdateLocationClick.bind(this);
    this.handleOpenImportModelClick = this.handleOpenImportModelClick.bind(this);
    this.toggleValidationOn = this.toggleValidationOn.bind(this);

    this.handleFilterFeatureSelect = this.handleFilterFeatureSelect.bind(this);
    this.handleDefaultFeatureSelect = this.handleDefaultFeatureSelect.bind(this);
    this.handleFilterPaddockNameSelect = this.handleFilterPaddockNameSelect.bind(this);

    this.handleFilterById = this.handleFilterById.bind(this);

    this.handleImportModalOpen = this.handleImportModalOpen.bind(this);
    this.handleImportModalSave = this.handleImportModalSave.bind(this);
    this.handleImportModalCancel = this.handleImportModalCancel.bind(this);
    this.handleImportModalFileChange = this.handleImportModalFileChange.bind(this);

    this.handleImportFromFarmIsOpen = this.handleImportFromFarmIsOpen.bind(this);
    this.handleImportFromFarmModelCancel = this.handleImportFromFarmModelCancel.bind(this);
    this.handleOpenImportFromFarmModelClick = this.handleOpenImportFromFarmModelClick.bind(this);
    this.setFarmForImportingFeatures = this.setFarmForImportingFeatures.bind(this);

    this.handleExportFeatureCollectionClick = this.handleExportFeatureCollectionClick.bind(this);
    this.handleImportFeatureCollectionFromOtherFarmsClick =
      this.handleImportFeatureCollectionFromOtherFarmsClick.bind(this);

    this.handleGenerateWaterways = this.handleGenerateWaterways.bind(this);
    this.handleGenerateFullFarmIrrigatedArea = this.handleGenerateFullFarmIrrigatedArea.bind(this);
  }

  componentWillMount() {
    const { map, farmsLoad: propsFarmsLoad, userAdmin, authFarmId } = this.props;
    propsFarmsLoad();
    if (!userAdmin) {
      this.handleChangeFarmId(authFarmId);
      // this.toggleValidationOn(); not used at the moment
    } else if (map.featureCollectionSourceId) {
      this.handleChangeFarmId(map.featureCollectionSourceId);
    }
  }

  componentDidUpdate(prevProps) {
    const { farmsCollection, map, mapReset: propsMapReset } = this.props;
    if (prevProps.farmsCollection.length !== farmsCollection.length) {
      if (map.featureCollectionSourceId) {
        const farmId = map.featureCollectionSourceId;
        const farm = farmsCollection.find((farmItem) => farmItem.id === farmId);
        if (!farm) {
          propsMapReset();
        }
      }
    }
  }

  handleLogoutClick = () => {
    this.handleResetClick();
    Auth.signOut();
  };

  handleClearErrorsClick() {
    this.setState((prevState) => ({ showErrors: !prevState.showErrors }));
  }

  handleResetClick() {
    const {
      mapReset: propsMapReset,
      featureCollectionReset: propsFeatureCollectionReset,
      draftFeatureCollectionReset: propsDraftFeatureCollectionReset,
      setFarmId,
    } = this.props;
    setFarmId(null);
    this.handleClearErrorsClick();
    propsMapReset();
    propsFeatureCollectionReset();
    propsDraftFeatureCollectionReset();
    this.toggleDraftEditing(null);
  }

  handleOpenImportModelClick() {
    this.handleImportModalOpen();
  }

  handleOpenImportFromFarmModelClick() {
    this.handleImportFromFarmIsOpen();
  }

  handleCreateFeatures(farmId) {
    const { map, farmsLoad: propsFarmsLoad, featureCollectionCreate: propsFeatureCollectionCreate } = this.props;
    this.setState({
      isPublishingFarm: true,
    });
    propsFeatureCollectionCreate(
      {
        farmId,
        featureCollection: map.featureCollection,
      },
      null,
    )
      .then(() => {
        this.setState({
          isPublishingFarm: false,
          publishModalOpen: false,
        });
      })
      .then(() => {
        propsFarmsLoad().then(() => {
          this.handleChangeFarmId(farmId);
        });
      });
  }

  toggleValidationOn() {
    this.setState((prevState) => ({ validationOn: !prevState.validationOn }));
  }

  setFarmForImportingFeatures(farms) {
    this.setState({
      farmsToImportFeaturesFrom: farms,
    });
  }

  handleCreateDraft(id) {
    const {
      map,
      farmsLoad: propsFarmsLoad,
      draftFeatureCollectionCreate: propsDraftFeatureCollectionCreate,
    } = this.props;
    this.toggleDraftEditing(true);
    propsDraftFeatureCollectionCreate(
      {
        farmId: id,
        draftFeatureCollection: map.featureCollection,
      },
      null,
    ).then(() => {
      propsFarmsLoad().then(() => {
        this.handleChangeFarmId(id);
      });
    });
  }

  handleClickSave() {
    const { draftEditing } = this.state;
    if (draftEditing) {
      this.handleSaveDraftFeatures();
    } else {
      this.handleSaveFeatures();
    }
  }

  handleSaveFeatures() {
    const {
      map,
      featureCollectionCreate: propsFeatureCollectionCreate,
      featureCollectionLoad: propsFeatureCollectionLoad,
    } = this.props;
    const farmId = map.featureCollectionSourceId;
    const wrappedFeatureCollection = wrapAllFeatures(map.featureCollection);
    GFV.valid(wrappedFeatureCollection, 'persisting', (pValid, pErrors) => {
      if (!pValid) {
        this.setState({ importErrors: pErrors });
      } else {
        this.setState({ importErrors: [] });
        propsFeatureCollectionCreate(
          {
            farmId,
            featureCollection: wrappedFeatureCollection,
          },
          null,
        ).then(() => {
          propsFeatureCollectionLoad({ farmId });
        });
      }
    });
  }

  handleSaveDraftFeatures() {
    const {
      map,
      draftFeatureCollection,
      draftFeatureCollectionCreate: propsDraftFeatureCollectionCreate,
      draftFeatureCollectionLoad: propsDraftFeatureCollectionLoad,
    } = this.props;
    const { validationOn } = this.state;
    const farmId = map.featureCollectionSourceId;
    const wrappedFeatureCollection = wrapAllFeatures(map.featureCollection);
    if (validationOn) {
      GFV.valid(wrappedFeatureCollection, 'persisting', (pValid, pErrors) => {
        if (!pValid) {
          this.setState({ importErrors: pErrors });
        } else {
          this.setState({ importErrors: [] });
          propsDraftFeatureCollectionCreate(
            {
              farmId,
              draftFeatureCollection: wrappedFeatureCollection,
              version: draftFeatureCollection.version,
            },
            null,
          ).then(() => {
            propsDraftFeatureCollectionLoad({ farmId });
          });
        }
      });
    } else {
      GFV.valid(wrappedFeatureCollection, 'persisting', (pValid, pErrors) => {
        this.setState({ importErrors: pErrors });
      });
      propsDraftFeatureCollectionCreate(
        {
          farmId,
          draftFeatureCollection: wrappedFeatureCollection,
          version: draftFeatureCollection.version,
        },
        null,
      ).then(() => {
        propsDraftFeatureCollectionLoad({ farmId });
      });
    }
  }

  handleUpdateLocationClick() {
    const {
      map,
      featureCollectionCreate: propsFeatureCollectionCreate,
      featureCollectionLoad: propsFeatureCollectionLoad,
      draftFeatureCollectionCreate: propsDraftFeatureCollectionCreate,
      draftFeatureCollectionLoad: propsDraftFeatureCollectionLoad,
      draftFeatureCollection,
    } = this.props;
    const { draftEditing } = this.state;
    const farmId = map.featureCollectionSourceId;

    const newFeatureCollection = map.featureCollection;
    if (draftEditing) {
      propsDraftFeatureCollectionCreate(
        {
          farmId,
          draftFeatureCollection: newFeatureCollection,
          version: draftFeatureCollection.version,
        },
        null,
      ).then(() => {
        propsDraftFeatureCollectionLoad({ farmId });
      });
    } else if (draftEditing === false) {
      GFV.valid(newFeatureCollection, 'persisting', (pValid, pErrors) => {
        if (!pValid) {
          this.setState({ importErrors: pErrors });
        } else {
          this.setState({ importErrors: [] });
          propsFeatureCollectionCreate(
            {
              farmId,
              featureCollection: newFeatureCollection,
            },
            null,
          ).then(() => {
            propsFeatureCollectionLoad({ farmId });
          });
        }
      });
    }
  }

  handleChangeFarmId(farmId) {
    const {
      featureCollectionLoad: propsFeatureCollectionLoad,
      draftFeatureCollectionLoad: propsDraftFeatureCollectionLoad,
      userAdmin,
      userEmail,
      setFarmId,
    } = this.props;
    this.handleResetClick();
    this.setState({ importErrors: [] });
    setFarmId(farmId);

    const getFeatures = async () => {
      const hasDraftFeatures = await propsDraftFeatureCollectionLoad({ farmId }).then((data) => {
        if (data != null) {
          GFV.valid(data.draftFeatureCollection, 'persisting', (pValid, pErrors) => {
            this.setState({ importErrors: pErrors });
          });
          this.toggleDraftEditing(true);
          this.loadMapData(farmId, data.draftFeatureCollection, data.updatedAt);
          return true;
        }
      });
      if (hasDraftFeatures) {
        return FEATURE_COLLECTION_RESULT_ENUM.DRAFT_FEATURE_COLLECTION;
        // fetch live features if admin user
      } else if (userAdmin || inWhiteList(userEmail)) {
        const hasFeatures = await propsFeatureCollectionLoad({ farmId }).then((data) => {
          // data will always return but will be an empty array if no features
          if (data?.featureCollection?.features?.length > 0) {
            this.toggleDraftEditing(false);
            this.loadMapData(farmId, data.featureCollection, data.lastUpdated);
            return true;
          }
        });
        if (hasFeatures) {
          return FEATURE_COLLECTION_RESULT_ENUM.FEATURE_COLLECTION;
        } else {
          return FEATURE_COLLECTION_RESULT_ENUM.NO_FEATURE_COLLECTION;
        }
      }
    };
    getFeatures().then((res) => {
      // allow create new draft if no exising features and is an admin user
      if (res === FEATURE_COLLECTION_RESULT_ENUM.NO_FEATURE_COLLECTION && userAdmin) {
        this.handleCreateDraft(farmId);
      }
    });
  }

  loadMapData(farmId, foundFeatureCollection, lastUpdatedDate) {
    const { map, mapSet: propsMapSet } = this.props;
    const datumFeature = foundFeatureCollection.features.find((feature) => feature.properties.type === 'datum');
    const [longitude, latitude] = _get(datumFeature, 'geometry.coordinates', []);
    const mapData = {
      zoom: 18,
      featureCollectionSourceId: farmId,
      featureCollection: foundFeatureCollection,
      lat: latitude || map.defaultLat,
      lng: longitude || map.defaultLng,
      lastUpdatedDate,
    };
    propsMapSet(mapData);
  }

  togglePaddockNameOpenClick() {
    const {
      map,
      map: {
        filter: { filterFeatures },
      },
    } = this.props;
    const paddockEnabled =
      !!map.featureCollectionSourceId &&
      (filterFeatures.length === 0 || _includes(filterFeatures, GEOJSON_PROPERTY_TYPES.PADDOCK));
    const { paddockNameDropdownOpen } = this.state;

    this.setState({
      paddockNameDropdownOpen: paddockNameDropdownOpen || paddockEnabled ? !paddockNameDropdownOpen : false,
    });
  }

  togglePathFilterOpenClick() {
    const {
      map,
      map: {
        filter: { filterFeatures },
      },
    } = this.props;
    const pathEnabled =
      !!map.featureCollectionSourceId &&
      (filterFeatures.length === 0 || _includes(filterFeatures, GEOJSON_PROPERTY_TYPES.EXIT_PATH));
    const { pathFilterDropDown } = this.state;

    this.setState({
      pathFilterDropDown: pathFilterDropDown || pathEnabled ? !pathFilterDropDown : false,
    });
  }

  toggleFeatureOpenClick() {
    const { map } = this.props;
    const { featureDropdownOpen } = this.state;
    const featureEnabled = !!map.featureCollectionSourceId;
    this.setState({
      featureDropdownOpen: featureDropdownOpen || featureEnabled ? !featureDropdownOpen : false,
    });
  }

  openConfirmPublishModal() {
    this.setState({
      publishModalOpen: true,
    });
  }

  closeConfirmPublishModal() {
    this.setState({
      publishModalOpen: false,
    });
  }

  toggleDraftEditing(editMode) {
    this.setState({
      draftEditing: editMode,
    });
  }

  toggleMenuOpen() {
    const { menuOpen } = this.state;
    this.setState({
      menuOpen: !menuOpen,
    });
  }

  handleFilterById(e) {
    const filterFeatureById = e.currentTarget.value;
    this.setState({ filterFeatureById });
    const { map, mapSet: propsMapSet } = this.props;

    propsMapSet({
      filter: {
        ...map.filter,
        filterFeatureById,
      },
    });
  }

  async handleGenerateWaterways() {
    const { map, mapSet: propsMapSet } = this.props;

    const featureCollection = map.featureCollection;

    const datum = featureCollection.features.find((feature) => feature.properties.type === 'datum');
    const farmBoundary = featureCollection.features.find((feature) => feature.properties.type === 'farm-boundary');

    const waterwayPolygonFeatures = await generateWaterwayFeatures(datum, farmBoundary);

    const featuresWithoutWaterways = featureCollection.features.filter(
      (feature) => feature.properties.type !== 'waterway',
    );
    featureCollection.features = [...featuresWithoutWaterways, ...waterwayPolygonFeatures];

    const mapData = { ...map, featureCollection };
    propsMapSet({ ...mapData, filter: { filterFeatures: [...mapData.filter.filterFeatures, 'waterway'] } });
  }

  async handleGenerateFullFarmIrrigatedArea() {
    const { map, mapSet: propsMapSet } = this.props;

    const featureCollection = map.featureCollection;

    const farmBoundary = featureCollection.features.find((feature) => feature.properties.type === 'farm-boundary');
    const farmBoundaryGeometry = farmBoundary.geometry.coordinates;

    const fullFarmIrrigatedArea = polygon(farmBoundaryGeometry, {
      type: 'irrigated-area',
      name: 'full farm irrigated area',
    });

    const bufferedFullFarmIrrigatedArea = turfBuffer(fullFarmIrrigatedArea, 5, { units: 'meters' });

    const featuresWithoutIrrigatedAreas = featureCollection.features.filter(
      (feature) => feature.properties.type !== 'irrigated-area',
    );

    featureCollection.features = [...featuresWithoutIrrigatedAreas, bufferedFullFarmIrrigatedArea];

    const mapData = { ...map, featureCollection };
    propsMapSet({ ...mapData, filter: { filterFeatures: [...mapData.filter.filterFeatures, 'irrigated-area'] } });
  }

  handleExportFeatureCollectionClick() {
    const {
      map: { featureCollection },
    } = this.props;
    const blob = new Blob([JSON.stringify(featureCollection)], { type: 'application/json' });
    saveAs(blob, 'feature-collection.json');
  }

  async handleImportFeatureCollectionFromOtherFarmsClick() {
    const {
      map: { featureCollection },
      mapSet: propsMapSet,
    } = this.props;
    const { farmsToImportFeaturesFrom } = this.state;
    const consolidatedFeatureCollection = await consolidateFeatures(featureCollection, farmsToImportFeaturesFrom);
    const blob = new Blob([JSON.stringify(consolidatedFeatureCollection)], { type: 'application/json' });
    saveAs(blob, 'consolidated-feature-collection.json');

    GJV.valid(consolidatedFeatureCollection, (gValid, gErrors) => {
      if (!gValid) {
        this.setState({ importErrors: gErrors });
      } else {
        GFV.valid(consolidatedFeatureCollection, 'importing', (pValid, pErrors) => {
          if (!pValid) {
            this.setState({ importErrors: pErrors });
          } else {
            this.setState({ importErrors: [] });
            const datumFeature = consolidatedFeatureCollection.features.find(
              (feature) => feature.properties.type === 'datum',
            );
            const [longitude, latitude] = _get(datumFeature, 'geometry.coordinates', []);
            const mapData = {
              zoom: 18,
              featureCollection: consolidatedFeatureCollection,
              lat: latitude || map.defaultLat,
              lng: longitude || map.defaultLng,
              rebased: false,
              persisted: false,
            };
            propsMapSet(mapData);
          }
        });
      }
    });
    this.handleImportFromFarmModelCancel();
  }

  handleImportModalOpen() {
    this.setState({
      importModalIsOpen: true,
      importFile: '',
    });
  }

  handleImportFromFarmIsOpen() {
    this.setState({
      importFromFarmModalIsOpen: true,
    });
  }

  handleImportModalSave() {
    const { importFile, validationOn } = this.state;
    this.setState({
      importModalIsOpen: false,
      importFile: '',
    });
    // const importFile = new Blob([JSON.stringify(require('../test/fixtures/invalid_feature_collection.json'))], { type: 'application/json' });
    const read = new FileReader();
    read.readAsText(importFile);
    read.onloadend = () => {
      const { map, mapSet: propsMapSet } = this.props;
      let data = '{"type": "FeatureCollection", "features": []}';
      try {
        data = JSON.parse(read.result);
      } catch {
        data = undefined;
      }
      if (data) {
        if (validationOn) {
          GJV.valid(data, (gValid, gErrors) => {
            if (!gValid) {
              this.setState({ importErrors: gErrors });
            } else {
              GFV.valid(data, 'importing', (pValid, pErrors) => {
                if (!pValid) {
                  this.setState({ importErrors: pErrors });
                } else {
                  this.setState({ importErrors: [] });
                  const datumFeature = data.features.find((feature) => feature.properties.type === 'datum');
                  const [longitude, latitude] = _get(datumFeature, 'geometry.coordinates', []);
                  const mapData = {
                    zoom: 18,
                    featureCollection: data,
                    lat: latitude || map.defaultLat,
                    lng: longitude || map.defaultLng,
                    rebased: false,
                    persisted: false,
                  };
                  propsMapSet(mapData);
                }
              });
            }
          });
        } else {
          const processedData = processMapFile(data);
          const centerOfFeatureCollection = turfCenter(processedData);
          const [longitude, latitude] = _get(centerOfFeatureCollection, 'geometry.coordinates', []);
          const mapData = {
            zoom: 18,
            featureCollection: processedData,
            lat: latitude || map.defaultLat,
            lng: longitude || map.defaultLng,
            rebased: false,
            persisted: false,
          };
          propsMapSet(mapData);
        }
      } else {
        // eslint-disable-next-line no-alert
        alert('Invalid JSON. Please check file for errors');
      }
    };
  }

  handleImportModalCancel() {
    this.setState({
      importModalIsOpen: false,
      importFile: '',
    });
  }

  handleImportFromFarmModelCancel() {
    this.setState({
      importFromFarmModalIsOpen: false,
    });
  }

  handleImportModalFileChange(e) {
    this.setState({ importFile: e.target.files[0] });
  }

  handleFilterFeatureSelect(e) {
    const {
      map,
      map: {
        filter: { filterFeatures },
      },
      mapSet: propsMapSet,
    } = this.props;

    let newFilterFeatures = _cloneDeep(filterFeatures);
    const feature = e.currentTarget.getAttribute('data-id');
    if (feature === 'all') {
      newFilterFeatures = [];
    } else {
      const index = _indexOf(newFilterFeatures, feature);
      if (index > -1) {
        newFilterFeatures.splice(index, 1);
      } else {
        newFilterFeatures = [...newFilterFeatures, feature];
      }
    }
    const paddockEnabled =
      map.featureCollectionSourceId && (newFilterFeatures.length === 0 || _includes(newFilterFeatures, 'paddock'));

    propsMapSet({
      filter: {
        ...map.filter,
        filterFeatures: newFilterFeatures,
        ...(!paddockEnabled && { filterPaddockName: '' }),
      },
    });
  }

  handleDefaultFeatureSelect() {
    const { mapSet: propsMapSet } = this.props;
    propsMapSet({
      filter: {
        ...map.filter,
        filterFeatures: defaultFilterFeatures,
      },
    });
  }

  handleFilterPaddockNameSelect(e) {
    const { map, mapSet: propsMapSet } = this.props;
    let paddockName = e.currentTarget.getAttribute('data-id');
    if (paddockName === 'all') {
      paddockName = '';
    }

    propsMapSet({
      filter: {
        ...map.filter,
        filterFeatures:
          paddockName === ''
            ? []
            : [GEOJSON_PROPERTY_TYPES.PADDOCK, GEOJSON_PROPERTY_TYPES.EXIT_POINT, GEOJSON_PROPERTY_TYPES.TROUGH],
        filterPaddockName: paddockName,
      },
    });
  }

  resetFilters() {
    const { mapSet: propsMapSet } = this.props;
    propsMapSet({
      filter: { filterFeatures: [], filterPaddockName: '' },
    });
  }

  farmHasLocation() {
    const { map } = this.props;
    let farmHasLocation = true;
    if (map.featureCollectionSourceId) {
      const datum = map.featureCollection.features.find((feature) => feature.properties.type === 'datum');
      if (!datum) {
        farmHasLocation = false;
      }
    }
    return farmHasLocation;
  }

  farmHasDatum() {
    const { map } = this.props;
    let farmHasDatum = true;
    if (map.featureCollectionSourceId) {
      const datum = map.featureCollection.features.find((feature) => feature.properties.type === 'datum');
      if (!datum) {
        farmHasDatum = false;
      }
    }
    return farmHasDatum;
  }

  closeSetAddress() {
    this.setState({
      setAddressOpen: false,
    });
  }

  render() {
    const {
      filterFeatureById,
      importModalIsOpen,
      importFromFarmModalIsOpen,
      farmsToImportFeaturesFrom,
      featureDropdownOpen,
      paddockNameDropdownOpen,
      pathFilterDropDown,
      importErrors,
      menuOpen,
      publishModalOpen,
      draftEditing,
      setAddressOpen,
      validationOn,
      showErrors,
      isPublishingFarm,
    } = this.state;

    const { map, farmsCollection, authState, userAdmin, userEmail, containerWidth, farmId } = this.props;

    const {
      persisted: mapPersisted,
      filter: { filterFeatures, filterPaddockName },
      lastUpdatedDate,
    } = map;

    const featureEnabled = map.featureCollectionSourceId;
    const paddockEnabled = featureEnabled && (filterFeatures.length === 0 || _includes(filterFeatures, 'paddock'));
    const paddockFilter = map.featureCollection.features
      .filter((feature) => feature.properties.type === GEOJSON_PROPERTY_TYPES.PADDOCK)
      .map((feature) => ({
        name: feature.properties.name.toString(),
        complexPaddockType: feature.properties.complexPaddockType,
      }))
      .sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: 'base',
        }),
      );
    const pathFilter = map.featureCollection.features.filter(
      (feature) => feature.properties.type === GEOJSON_PROPERTY_TYPES.EXIT_PATH,
    );
    const currentFarmName = farmsCollection.find((farmItem) => farmItem.id === farmId)?.name ?? '';
    const farmHeader = userAdmin ? `${currentFarmName} (Admin User)` : `${currentFarmName} farm`;
    // eslint-disable-next-line no-nested-ternary
    const editMode = draftEditing == null ? '' : draftEditing ? 'Draft' : 'Live';

    return (
      <div>
        <div
          className="user-bar"
          style={{
            height: '64px',
            color: 'white',
            backgroundColor: 'black',
            top: '0px',
            zIndex: 5,
            position: 'absolute',
            right: '24px',
            padding: '12px 0px',
            fontSize: '20px',
            display: 'flex',
            alignItems: 'baseline',
          }}
        >
          <p>{farmHeader}</p>
          <span style={{ marginLeft: '16px' }} />
          <Confirm
            onConfirm={this.handleLogoutClick}
            title="Confirm Logout"
            body="Logout of the application? You will loose any unsaved changes."
            confirmText="Logout"
          >
            <Button
              type="button"
              title="logout"
              className="btn btn-dark"
              disabled={authState !== 'signedIn'}
            >
              <img
                src={logoutIcon}
                style={{ marginLeft: '-4px' }}
                alt="logoutIcon"
              />
            </Button>
          </Confirm>
          <Button
            type="button"
            className="btn btn-dark"
            style={{ marginLeft: '8px' }}
            onClick={() =>
              window.open(`${intercomHelpUrl}en/articles/5770973-using-the-mapping-tool`, '_blank').focus()
            }
          >
            <FontAwesomeIcon icon="question" />
          </Button>
        </div>
        <Modal
          isOpen={importModalIsOpen}
          contentLabel="Import Modal"
          style={modalStyles}
          shouldCloseOnOverlayClick={false}
        >
          <p>
            <strong>Import GeoJSON File</strong>
          </p>
          <p>
            The farm&apos;s geometry will be set to the Feature Collection from your imported file.
            <br /> Cancel now to keep existing collection
          </p>
          <form>
            <label htmlFor="toolbar-name">
              <span style={{ paddingRight: '15px' }}>File</span>
              <input
                id="toolbar-import"
                type="file"
                value={undefined}
                onChange={this.handleImportModalFileChange}
              />
            </label>
          </form>
          <br />
          <div className="d-flex flex-row justify-content-between mt-4">
            <button
              type="button"
              onClick={this.handleImportModalCancel}
              className="btn btn-outline-secondary"
            >
              Cancel
            </button>
            <span style={{ paddingRight: '5px' }} />
            <button
              type="button"
              onClick={this.handleImportModalSave}
              className="btn btn-success"
            >
              Import
            </button>
          </div>
        </Modal>
        <ImportFarmFeaturesModal
          farmsCollection={farmsCollection}
          handleImportFeatureCollectionFromOtherFarmsClick={this.handleImportFeatureCollectionFromOtherFarmsClick}
          handleImportFromFarmModelCancel={this.handleImportFromFarmModelCancel}
          importFromFarmModalIsOpen={importFromFarmModalIsOpen}
          modalStyles={modalStyles}
          setFarmForImportingFeatures={this.setFarmForImportingFeatures}
          farmsToImportFeaturesFrom={farmsToImportFeaturesFrom}
          currentFarm={farmId}
        />
        <div
          className="map-toolbar"
          style={{ top: '64px' }}
        >
          <div
            style={{
              display: userAdmin || inWhiteList(userEmail) ? 'flex' : 'none',
              top: '24px',
              position: 'absolute',
            }}
          >
            <Button
              type="button"
              style={{
                color: 'white',
                backgroundColor: 'black',
                borderRadius: '8px',
                height: '48px',
                width: '48px',
                border: 'none',
                marginLeft: '16px',
                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.35)',
              }}
              onClick={this.toggleMenuOpen}
              title="Open Menu"
            >
              <FontAwesomeIcon icon={menuOpen ? 'times' : 'bars'} />
            </Button>
            <div
              style={{
                backgroundColor: editMode === 'Draft' ? '#FCB017' : '#FA3F48',
                width: editMode !== '' ? '237px' : '176px',
              }}
              className="edit-mode-status-bar"
            >
              <FarmListPicker
                allFarms={farmsCollection}
                currentFarmId={map.featureCollectionSourceId}
                currentFarmIdChange={this.handleChangeFarmId}
                borderColor={editMode === '' ? 'white' : editMode === 'Draft' ? '#FCB017' : '#FA3F48'}
              />
              <p className="edit-mode-text">{editMode}</p>
            </div>
            <div
              id="admin-menu"
              style={{
                display: menuOpen ? 'grid' : 'none',
                zIndex: 1001,
                position: 'absolute',
                width: '232px',
                backgroundColor: '#141518',
                borderRadius: '8px',
                top: '56px',
                left: '16px',
              }}
            >
              <Button
                type="button"
                className="menu-button"
                onClick={this.handleResetClick}
                title="Home"
              >
                <img
                  src={homeIcon}
                  style={{ marginRight: '8px' }}
                  alt={homeIcon}
                />
                Home
              </Button>
              <hr />
              <Button
                type="button"
                className="menu-button"
                onClick={this.openConfirmPublishModal}
                disabled={!map.featureCollectionSourceId || !draftEditing}
                title="Make features live"
              >
                <img
                  src={publishIcon}
                  style={{ marginRight: '8px' }}
                  alt="publishIcon"
                />
                Publish Farm
              </Button>
              <hr />
              <Button
                type="button"
                className="menu-button"
                onClick={this.handleOpenImportModelClick}
                disabled={!map.featureCollectionSourceId}
                title="import geojson"
              >
                <img
                  src={importFile}
                  style={{ marginRight: '8px' }}
                  alt="importFile"
                />
                Import geojson
              </Button>
              <hr />
              <Button
                type="button"
                className="menu-button"
                onClick={this.handleExportFeatureCollectionClick}
                title="export geojson"
              >
                <img
                  src={exportFile}
                  style={{ marginRight: '8px' }}
                  alt="exportFile"
                />
                Export geojson with ids
              </Button>
              <hr />
              {canImportFeaturesFromOtherFarms(userEmail) ? (
                <>
                  <Button
                    type="button"
                    className="menu-button"
                    onClick={this.handleImportFromFarmIsOpen}
                    disabled={!map.featureCollectionSourceId}
                    title="import from farm"
                  >
                    <img
                      src={importFile}
                      style={{ marginRight: '8px' }}
                      alt="importFile"
                    />
                    Import features from farm
                  </Button>
                  <hr />
                </>
              ) : null}
              <Button
                type="button"
                className="menu-button"
                onClick={this.handleGenerateWaterways}
                title="generateWaterways"
              >
                <img
                  src={riverIcon}
                  style={{ marginRight: '8px' }}
                  alt="generateWaterways"
                />
                Generate Waterways (LINZ)
              </Button>
              <hr />
              <Button
                type="button"
                className="menu-button"
                onClick={this.handleGenerateFullFarmIrrigatedArea}
                title="generateFullFarmIrrigatedArea"
              >
                <img
                  src={waterIcon}
                  style={{ marginRight: '8px' }}
                  alt="generateFullFarmIrrigatedArea"
                />
                Generate Full Farm Irrigated Area
              </Button>
              <hr />
              <Button
                type="button"
                className="menu-button"
                onClick={this.toggleValidationOn}
                title="Toggle Validation"
                disabled={!map.featureCollectionSourceId}
                style={{ color: validationOn ? 'lightgreen' : '#FCB017' }}
              >
                {`Validation: ${validationOn ? 'on' : 'off'}`}
              </Button>
            </div>
            {!this.farmHasDatum() && setAddressOpen ? (
              <div
                style={{
                  left: containerWidth / 2 - 240,
                  position: 'absolute',
                  width: '468px',
                  height: '184px',
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  padding: '24px',
                  top: '96px',
                }}
              >
                <p style={{ marginBottom: '8px' }}>
                  <strong>Step 1 of 3: Farm Address</strong>
                </p>
                {/* <br /> */}
                <p style={{ width: '364px', height: '40px', marginBottom: '24px' }}>
                  Enter the correct farm address address above or leave blank and click ‘Next’ to continue.
                </p>
                <button
                  type="button"
                  className="custom-black-button"
                  onClick={this.closeSetAddress}
                  style={{ padding: '8px' }}
                >
                  <p>Next</p>
                </button>
              </div>
            ) : null}
            {!this.farmHasDatum() && !setAddressOpen ? (
              <div
                style={{
                  left: containerWidth / 2 - 240,
                  position: 'absolute',
                  width: '468px',
                  height: '154px',
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  padding: '24px',
                  top: '96px',
                }}
              >
                <p style={{ marginBottom: '12px' }}>
                  <strong>Step 2 of 3: Add a Datum</strong>
                </p>
                <p>
                  Select the ‘Gate Icon’ on the right to add the datum
                  <br />
                  point.
                  <br />
                  Place it at the center of the farm or milkshed.
                </p>
              </div>
            ) : null}
            {this.farmHasDatum() && !this.farmHasLocation() ? (
              <div
                style={{
                  left: containerWidth / 2 - 240,
                  position: 'absolute',
                  width: '468px',
                  height: '184px',
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  padding: '24px',
                  top: '96px',
                }}
              >
                <p>
                  <strong>Step 3 of 3:</strong>
                  <br />
                </p>
                <p>Click ‘confirm’ when the location of the default center view has been set.</p>
                <button
                  type="button"
                  className="custom-black-button"
                  onClick={this.handleUpdateLocationClick}
                  style={{ padding: '8px' }}
                >
                  <p>Confirm</p>
                </button>
              </div>
            ) : null}
          </div>
          {mapPersisted || (
            <Button
              style={{
                left: '20em',
                top: '24px',
                position: 'absolute',
                borderRadius: '8px',
                height: '48px',
              }}
              type="button"
              color="danger"
              disabled
              className="text-nowrap"
            >
              <FontAwesomeIcon icon="exclamation" />
              <span style={{ paddingLeft: '0.5em' }}>Farm has not saved</span>
            </Button>
          )}
          {userAdmin || inWhiteList(userEmail) ? null : (
            <FarmerDownloadPanel handleExportFeatureCollectionClick={this.handleExportFeatureCollectionClick} />
          )}
          <div
            style={{
              backgroundColor: 'rgb(20, 21, 24, 0.6)',
              borderRadius: '8px',
              top: '96px',
              position: 'absolute',
              width: '232px',
              color: 'white',
              padding: '8px',
              marginLeft: '15px',
              display: featureEnabled && this.farmHasDatum() && this.farmHasLocation() ? 'block' : 'none',
              boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.35)',
            }}
          >
            {'Filter'}
            <Button
              style={{ marginLeft: '96px', backgroundColor: 'rgb(20, 21, 24, 0.6)' }}
              onClick={this.resetFilters}
            >
              Reset
            </Button>
            <ButtonGroup>
              <ButtonDropdown
                disabled={!featureEnabled}
                isOpen={featureDropdownOpen}
                toggle={this.toggleFeatureOpenClick}
                style={{ marginTop: '16px' }}
              >
                <DropdownToggle
                  className="select-farm"
                  style={{ width: '215px', height: '40px', color: 'black' }}
                  tag="div"
                >
                  <p className="filter-dropdown-style">
                    {filterFeatures.length === 0
                      ? 'All features'
                      : `${filterFeatures.length} ${filterFeatures.length === 1 ? 'feature' : 'features'}`}
                  </p>
                  <FontAwesomeIcon icon="caret-down" />
                </DropdownToggle>
                <DropdownMenu style={{ marginTop: '8px' }}>
                  <DropdownItem
                    toggle={false}
                    key="filter-by-id-field"
                    data-id="filter-by-id-field"
                    active={filterFeatureById != null && filterFeatureById.length > 0}
                  >
                    {'Filter by Ids: (comma separated)'}
                    <input
                      id="filter-by-id-field"
                      style={{ minWidth: '250px', display: 'block' }}
                      value={filterFeatureById}
                      onChange={this.handleFilterById}
                    />
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    toggle={false}
                    key="all"
                    data-id="all"
                    onClick={this.handleFilterFeatureSelect}
                    active={filterFeatures.length === 0 && (filterFeatureById ?? '').length === 0}
                  >
                    {'all'}
                  </DropdownItem>
                  <DropdownItem
                    toggle={false}
                    key="default"
                    data-id="default"
                    onClick={this.handleDefaultFeatureSelect}
                  >
                    {'default'}
                  </DropdownItem>
                  <DropdownItem divider />
                  {allPropertyTypes.map((type) => (
                    <DropdownItem
                      toggle={false}
                      key={type}
                      data-id={type}
                      onClick={this.handleFilterFeatureSelect}
                      active={_includes(filterFeatures, type)}
                    >
                      {type}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </ButtonDropdown>
            </ButtonGroup>
            <ButtonGroup style={{ marginTop: '8px' }}>
              <ButtonDropdown
                disabled={!paddockEnabled}
                isOpen={paddockNameDropdownOpen}
                toggle={this.togglePaddockNameOpenClick}
              >
                <DropdownToggle
                  className={paddockEnabled ? '' : 'disabled'}
                  style={{
                    width: '215px',
                    color: 'black',
                    backgroundColor: 'white',
                    border: '1px #E8EAED',
                    borderRadius: '8px',
                    height: '40px',
                  }}
                  tag="div"
                >
                  <p className="filter-dropdown-style">
                    {filterPaddockName || `All paddocks ${paddockFilter.length > 0 ? `(${paddockFilter.length})` : ''}`}
                  </p>
                  <FontAwesomeIcon icon="caret-down" />
                </DropdownToggle>
                <DropdownMenu className="paddocks-dropdown">
                  <DropdownItem
                    key="all"
                    data-id="all"
                    onClick={this.handleFilterPaddockNameSelect}
                    active={filterPaddockName === ''}
                  >
                    {'all'}
                  </DropdownItem>
                  <DropdownItem divider />
                  {paddockFilter.map((paddock) => (
                    <DropdownItem
                      key={paddock.name}
                      data-id={paddock.name}
                      onClick={this.handleFilterPaddockNameSelect}
                      active={filterPaddockName === paddock.name}
                    >
                      {paddock.name}
                      {suffixForComplexPaddocks(paddock)}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </ButtonDropdown>
            </ButtonGroup>
            <ButtonGroup style={{ marginTop: '8px', display: pathFilter.length > 0 ? 'block' : 'none' }}>
              <ButtonDropdown
                disabled={!paddockEnabled}
                isOpen={pathFilterDropDown}
                toggle={this.togglePathFilterOpenClick}
              >
                <DropdownToggle
                  style={{
                    width: '215px',
                    color: 'black',
                    backgroundColor: 'white',
                    border: '1px #E8EAED',
                    borderRadius: '8px',
                    height: '40px',
                  }}
                  tag="div"
                >
                  <p className="filter-dropdown-style">
                    {`All paths ${pathFilter.length > 0 ? `(${pathFilter.length})` : ''}`}
                  </p>
                  <FontAwesomeIcon icon="caret-down" />
                </DropdownToggle>
                <DropdownMenu
                  className="paddocks-dropdown"
                  style={{ width: '356px' }}
                >
                  <label style={{ padding: '8px', margin: '0px' }}>
                    Feature Id:
                    <input
                      id="filter-by-id-field"
                      style={{ minWidth: '326px', display: 'block' }}
                      value={filterFeatureById}
                      onChange={this.handleFilterById}
                      className="custom-input"
                    />
                  </label>
                  <DropdownItem divider />
                  <label style={{ padding: '8px', margin: '0px' }}>Select Path To Filter:</label>
                  <DropdownItem divider />
                  {pathFilter.map((path) => (
                    <DropdownItem
                      key={path.properties.id}
                      value={path.properties.id}
                      onClick={this.handleFilterById}
                      style={{
                        height: '40px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {`${path.properties.name} - ${path.properties.id}`}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </ButtonDropdown>
            </ButtonGroup>
          </div>
        </div>
        <div
          style={{
            position: 'absolute',
            left: '72px',
            zIndex: '1000',
            bottom: '20px',
            backgroundColor: 'rgb(20, 21, 24, 0.6)',
            color: 'white',
            borderRadius: '8px',
            padding: '8px',
            display: lastUpdatedDate ? 'block' : 'none',
          }}
        >
          {`Last Updated: ${new Date(lastUpdatedDate).toLocaleString()}`}
        </div>
        <div
          style={{
            position: 'absolute',
            right: '24px',
            top: '364px',
            zIndex: '1000',
            display: 'grid',
            gridGap: '8px',
          }}
        >
          <ToolbarButton
            icon={importErrors.length > 0 ? (showErrors ? 'times' : 'exclamation') : 'check'}
            backgroundColor={importErrors.length > 0 ? '#FA3F48' : '#0C9D7D'}
            onClick={this.handleClearErrorsClick}
            disabled={importErrors.length === 0}
          />
          <SaveFarmButton
            farmName={currentFarmName}
            userEmail={userEmail}
            onSave={this.handleClickSave}
            disabled={map.featureCollectionSourceId == null}
          />
          <ShowNotesButton
            farmName={currentFarmName}
            disabled={map.featureCollectionSourceId == null}
          />
        </div>
        {showErrors && importErrors.length > 0 ? (
          <IssuesModal
            handleClearErrorsClick={this.handleClearErrorsClick}
            importErrors={importErrors}
            userAdmin={userAdmin}
          />
        ) : null}
        <Modal
          isOpen={publishModalOpen}
          contentLabel="Publish Farm Modal"
          style={modalStyles}
          shouldCloseOnOverlayClick
        >
          <p>
            <strong style={{ fontSize: '18px' }}>Publish Farm</strong>
            <Button
              type="button"
              style={{
                color: 'black',
                backgroundColor: 'white',
                borderRadius: '8px',
                border: 'none',
                marginLeft: '155px',
              }}
              onClick={this.closeConfirmPublishModal}
              title="Publish Features"
            >
              <FontAwesomeIcon
                icon="times"
                style={{ fontSize: '24px' }}
              />
            </Button>
          </p>
          <p
            style={{
              overflowWrap: 'break-word',
              width: '302px',
              height: '60px',
              fontSize: '16px',
              lineHeight: '20px',
            }}
          >
            Are you sure you want to publish this farm and make all features go live? This action can't be undone.
          </p>
          <button
            type="button"
            className="custom-black-button"
            disabled={isPublishingFarm}
            onClick={() => this.handleCreateFeatures(map.featureCollectionSourceId)}
          >
            Publish
          </button>
          <span style={{ paddingRight: '24px' }} />
          <button
            type="button"
            className="custom-white-button"
            onClick={this.closeConfirmPublishModal}
          >
            Cancel
          </button>
        </Modal>
      </div>
    );
  }
}

const suffixForComplexPaddocks = (paddock) => {
  if (paddock == null || paddock.complexPaddockType == null) {
    return '';
  }

  if (paddock.complexPaddockType === ComplexPaddockTypeEnum.HIGH_COMPLEXITY_PADDOCK) {
    return ' (high complexity)';
  }
  return ' (low complexity)';
};

MapToolbar.propTypes = {
  featureCollectionLoad: PropTypes.func.isRequired,
  featureCollectionCreate: PropTypes.func.isRequired,
  draftFeatureCollectionLoad: PropTypes.func.isRequired,
  draftFeatureCollectionCreate: PropTypes.func.isRequired,
  farmsLoad: PropTypes.func.isRequired,
  mapSet: PropTypes.func.isRequired,
  mapReset: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  farmsCollection: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  map: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  featureCollection: PropTypes.object.isRequired,
  containerHeight: PropTypes.number.isRequired,
  containerWidth: PropTypes.number.isRequired,
  authState: PropTypes.string.isRequired,
  authFarmId: PropTypes.string.isRequired,
  userAdmin: PropTypes.bool.isRequired,
  userEmail: PropTypes.string.isRequired,
  userFarms: PropTypes.array.isRequired,
  draftFeatureCollection: PropTypes.object.isRequired,
  featureCollectionReset: PropTypes.func.isRequired,
  draftFeatureCollectionReset: PropTypes.func.isRequired,
  setFarmId: PropTypes.func.isRequired,
  farmId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  map: state.map,
  farmsCollection: state.farms.collection,
  featureCollection: state.featureCollection.model,
  draftFeatureCollection: state.draftFeatureCollection.model,
});

export default connect(mapStateToProps, {
  mapReset,
  mapSet,
  farmsLoad,
  featureCollectionReset,
  featureCollectionLoad,
  featureCollectionCreate,
  draftFeatureCollectionReset,
  draftFeatureCollectionLoad,
  draftFeatureCollectionCreate,
})(MapToolbar);
