var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
export var MultiFarmPicker = function (_a) {
    var allFarms = _a.allFarms, onSelectionChange = _a.onSelectionChange;
    var _b = useState(false), isDropDownOpen = _b[0], setIsDropDownOpen = _b[1];
    var toggleDropdownOpen = function () { return setIsDropDownOpen(function (prevState) { return !prevState; }); };
    var _c = useState([]), selectedFarmIds = _c[0], setSelectedFarmIds = _c[1];
    var toggleFarmSelection = function (farmId) {
        var isSelected = selectedFarmIds.includes(farmId);
        if (isSelected) {
            setSelectedFarmIds(selectedFarmIds.filter(function (id) { return id !== farmId; }));
        }
        else {
            setSelectedFarmIds(__spreadArray(__spreadArray([], selectedFarmIds, true), [farmId], false));
        }
    };
    var _d = useState(''), searchString = _d[0], setSearchString = _d[1];
    var onSearchInputChange = function (e) {
        var _a;
        setSearchString((_a = e.currentTarget.value) !== null && _a !== void 0 ? _a : '');
    };
    var _e = useState(allFarms), filteredFarms = _e[0], setFilteredFarms = _e[1];
    useEffect(function () {
        if (searchString === '' || searchString == null) {
            setFilteredFarms(allFarms);
        }
        else {
            var searchStringLower_1 = searchString.toLowerCase();
            setFilteredFarms(allFarms.filter(function (farm) { return farm.name.toLowerCase().indexOf(searchStringLower_1) >= 0; }));
        }
    }, [allFarms, searchString]);
    useEffect(function () {
        onSelectionChange(selectedFarmIds);
    }, [selectedFarmIds, onSelectionChange]);
    return (React.createElement(ButtonDropdown, { isOpen: isDropDownOpen, toggle: toggleDropdownOpen, title: "Select Farm", style: { paddingBottom: '8px' } },
        React.createElement(DropdownToggle, { tag: "div", style: { borderColor: 'white' } },
            React.createElement("input", { onFocus: function () { return setSearchString(''); }, className: "select-farm", style: { paddingLeft: '4px' }, value: searchString, placeholder: "Search farm", onChange: onSearchInputChange })),
        filteredFarms.length > 0 && (React.createElement(DropdownMenu, { className: "farms-dropdown" }, filteredFarms.map(function (farm) {
            var id = farm.id, name = farm.name;
            var isSelected = selectedFarmIds.includes(id);
            return (React.createElement(DropdownItem, { key: id, "data-id": id, onClick: function () { return toggleFarmSelection(id); }, active: isSelected, style: { height: '48px' } }, name));
        })))));
};
