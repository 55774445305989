var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { connectRouter, routerMiddleware } from 'connected-react-router';
import createHistory from 'history/createBrowserHistory';
import { reducer as notificationsReducer } from 'react-notification-system-redux';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';
import { initialState, reducers } from '../reducers';
export var history = createHistory();
var rootReducer = combineReducers(__assign(__assign({}, reducers), { router: connectRouter(history), notifications: notificationsReducer }));
var persistConfig = {
    key: 'topology181205',
    storage: storage,
    whitelist: ['map'],
};
var reducer = persistReducer(persistConfig, rootReducer);
export var store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(routerMiddleware(history), thunkMiddleware)));
export var persistor = persistStore(store);
