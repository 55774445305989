import { actionTypes } from '../constants';
export function mapReset() {
    return {
        type: actionTypes.MAP_RESET,
    };
}
export function mapSet(payload) {
    return {
        type: actionTypes.MAP_SET,
        payload: payload,
    };
}
