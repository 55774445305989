var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import _findIndex from 'lodash/findIndex';
export function fetch(state) {
    return __assign(__assign({}, state), { isFetching: true });
}
export function unfetch(state) {
    return __assign(__assign({}, state), { isFetching: false });
}
export function reset(state) {
    // this is initialstate
    return state;
}
export function loadSuccess(state, payload) {
    // this is initialstate
    return __assign(__assign({}, state), { collection: payload });
}
export function loadFailure(state) {
    // this is initialstate
    return reset(state);
}
export function unset(state) {
    return __assign(__assign({}, state), { model: {}, isFetching: false });
}
export function set(state, payload) {
    var model = state.collection.find(function (item) { return item.id === payload.id; });
    if (model) {
        return __assign(__assign({}, state), { model: model, isFetching: false });
    }
    return unset(state);
}
export function createSuccess(state, payload) {
    return __assign(__assign({}, state), { collection: __spreadArray(__spreadArray([], state.collection, true), [payload], false), isFetching: false });
}
export function createFailure(state) {
    return __assign(__assign({}, state), { isFetching: false });
}
export function updateSuccess(state, payload) {
    var index = _findIndex(state.collection, { id: payload.id });
    if (index > -1) {
        return __assign(__assign({}, state), { collection: __spreadArray(__spreadArray(__spreadArray([], state.collection.slice(0, index), true), [payload], false), state.collection.slice(index + 1), true), isFetching: false });
    }
    return __assign(__assign({}, state), { isFetching: false });
}
export function updateFailure(state) {
    return __assign(__assign({}, state), { isFetching: false });
}
export function destroySuccess(state, payload) {
    var index = _findIndex(state.collection, { id: payload.id });
    if (index > -1) {
        return __assign(__assign({}, state), { collection: __spreadArray(__spreadArray([], state.collection.slice(0, index), true), state.collection.slice(index + 1), true), isFetching: false });
    }
    return __assign(__assign({}, state), { isFetching: false });
}
export function destroyFailure(state) {
    return __assign(__assign({}, state), { isFetching: false });
}
