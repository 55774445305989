import React, { useEffect, useState } from 'react';
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
export var FarmListPicker = function (_a) {
    var allFarms = _a.allFarms, currentFarmId = _a.currentFarmId, currentFarmIdChange = _a.currentFarmIdChange, borderColor = _a.borderColor;
    var _b = useState(false), isDropDownOpen = _b[0], setIsDropDownOpen = _b[1];
    var toggleDropdownOpen = function () { return setIsDropDownOpen(function (prevState) { return !prevState; }); };
    var _c = useState(''), searchString = _c[0], setSearchString = _c[1];
    var onSearchInputChange = function (e) {
        var _a;
        setSearchString((_a = e.currentTarget.value) !== null && _a !== void 0 ? _a : '');
    };
    var _d = useState(allFarms), filteredFarms = _d[0], setFilteredFarms = _d[1];
    useEffect(function () {
        if (searchString === '' || searchString == null) {
            setFilteredFarms(allFarms);
        }
        else {
            var searchStringLower_1 = searchString.toLowerCase();
            setFilteredFarms(allFarms.filter(function (farm) { return farm.name.toLowerCase().indexOf(searchStringLower_1) >= 0; }));
        }
    }, [allFarms, searchString]);
    var _e = useState(undefined), currentFarmName = _e[0], setCurrentFarmName = _e[1];
    useEffect(function () {
        var _a;
        setCurrentFarmName((_a = allFarms.find(function (farm) { return farm.id === currentFarmId; })) === null || _a === void 0 ? void 0 : _a.name);
    }, [currentFarmId, allFarms]);
    return (React.createElement(ButtonDropdown, { isOpen: isDropDownOpen, toggle: toggleDropdownOpen, title: "Select Farm" },
        React.createElement(DropdownToggle, { tag: "div", style: { borderColor: borderColor } },
            React.createElement("input", { onFocus: function () { return setSearchString(''); }, className: "select-farm", style: { paddingLeft: '4px' }, value: searchString, placeholder: currentFarmName !== null && currentFarmName !== void 0 ? currentFarmName : 'Search farm', onChange: onSearchInputChange })),
        filteredFarms.length > 0 && (React.createElement(DropdownMenu, { className: "farms-dropdown" }, filteredFarms.map(function (farm) {
            var id = farm.id, name = farm.name;
            return (React.createElement(React.Fragment, { key: id },
                React.createElement(DropdownItem, { key: id, "data-id": id, onClick: function () { return currentFarmIdChange(id); }, active: id === currentFarmId, style: { height: '48px' } }, name)));
        })))));
};
