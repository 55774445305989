var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as turf from '@turf/turf';
var LINZ_API_TOKEN = '23a8d877755948c1a5e9672ece2ecbda'; // not super secret, can generate for free
var LINZ_API_URL = 'https://data.linz.govt.nz/services/query/v1/vector.json';
function fetchLinzData(layer, x, y) {
    return __awaiter(this, void 0, void 0, function () {
        var params, url, response, data, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    params = {
                        key: LINZ_API_TOKEN,
                        layer: layer,
                        x: x,
                        y: y,
                        max_results: 100,
                        radius: 10000,
                        geometry: true,
                        with_field_names: false,
                    };
                    url = new URL(LINZ_API_URL);
                    Object.keys(params).forEach(function (key) { return url.searchParams.append(key, params[key]); });
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, fetch(url)];
                case 2:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 3:
                    data = _a.sent();
                    return [2 /*return*/, data.vectorQuery.layers[layer]];
                case 4:
                    error_1 = _a.sent();
                    console.error('Error:', error_1);
                    return [2 /*return*/, []];
                case 5: return [2 /*return*/];
            }
        });
    });
}
function getWaterwayPolygons(x, y) {
    return __awaiter(this, void 0, void 0, function () {
        var polygonLayer, polygonsFromLinz, centrelinesLayer, centrelinesFromLinz, bufferDistance, polygonsFromCentrelines, combinedFeatures;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    polygonLayer = 50328;
                    return [4 /*yield*/, fetchLinzData(polygonLayer, x, y)];
                case 1:
                    polygonsFromLinz = _a.sent();
                    centrelinesLayer = 50327;
                    return [4 /*yield*/, fetchLinzData(centrelinesLayer, x, y)];
                case 2:
                    centrelinesFromLinz = _a.sent();
                    bufferDistance = 3;
                    polygonsFromCentrelines = centrelinesFromLinz.features.flatMap(function (feature) {
                        if (feature.geometry.type === 'LineString') {
                            return turf.buffer(feature, bufferDistance, { units: 'meters', steps: 1 });
                        }
                        return [feature];
                    });
                    combinedFeatures = __spreadArray(__spreadArray([], polygonsFromLinz.features, true), polygonsFromCentrelines, true);
                    return [2 /*return*/, combinedFeatures];
            }
        });
    });
}
export function generateWaterwayFeatures(datum, farmBoundary) {
    return __awaiter(this, void 0, void 0, function () {
        var datumCoords, waterwayPolygons, farmBoundaryBuffered, waterwayFeatures, deduplicatedFeatures, waterwayFeaturesWithProperties;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    datumCoords = datum.geometry.coordinates;
                    return [4 /*yield*/, getWaterwayPolygons(datumCoords[0], datumCoords[1])];
                case 1:
                    waterwayPolygons = _a.sent();
                    farmBoundaryBuffered = turf.buffer(farmBoundary, 3, { units: 'meters' });
                    waterwayFeatures = waterwayPolygons
                        .flatMap(function (waterway) {
                        var intersection = turf.intersect(waterway, farmBoundaryBuffered);
                        if (intersection != null && intersection.geometry.type === 'MultiPolygon') {
                            return intersection.geometry.coordinates.map(function (coords) { return turf.polygon(coords); });
                        }
                        return intersection ? [intersection] : [];
                    })
                        .filter(function (feature) { return feature.geometry.coordinates.length > 0; });
                    deduplicatedFeatures = waterwayFeatures.reduce(function (acc, feature) {
                        var isDuplicate = acc.some(function (existingFeature) {
                            return turf.booleanEqual(existingFeature, feature);
                        });
                        if (!isDuplicate) {
                            acc.push(feature);
                        }
                        return acc;
                    }, []);
                    waterwayFeaturesWithProperties = deduplicatedFeatures.map(function (feature) {
                        feature.properties = __assign(__assign({}, feature.properties), { type: 'waterway', name: 'waterway' });
                        return feature;
                    });
                    return [2 /*return*/, waterwayFeaturesWithProperties];
            }
        });
    });
}
