import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
export var ToolbarButton = function (_a) {
    var icon = _a.icon, backgroundColor = _a.backgroundColor, onClick = _a.onClick, disabled = _a.disabled;
    return (React.createElement(Button, { type: "button", style: {
            backgroundColor: backgroundColor,
            width: '40px',
            height: '40px',
            borderRadius: '8px',
            borderColor: backgroundColor,
            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.35)',
        }, onClick: onClick, disabled: disabled },
        React.createElement(FontAwesomeIcon, { icon: icon })));
};
